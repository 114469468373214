import React, { useState, useEffect } from "react";
import { Form } from "../../../components/patientComponents/styled-components/profile/styled";
import { useForm } from "react-hook-form";
import { MarginSeparator } from "../../../components/patientComponents/styled-components/login/styled";
import {
  callApi,
  patientUpdateHealth,
  getPreRegisterSelects,
  getHealthProgramByType,
  getDoctors,
  getDispensers,
  postAddDoctor,
  postAddDispenser,
} from "../../../services/patientServices/apiList";
import {
  AffiliationTypeList,
  HealthProgramTypeList,
  PlaceAttentionList,
  HealthProgramTypeEnum,
  PlaceAttentionEnum,
  RegimeList,
} from "../../../constants/patienteConstants/enums";
import RadioOutlinedInput from "../../../components/patientComponents/component/form/outlined-inputs/radio";
import Autocomplete from "../../../components/patientComponents/component/form/outlined-inputs/autocomplete";
import TextOutlinedInput from "../../../components/patientComponents/component/form/outlined-inputs/text";
import CheckboxOutlinedInput from "../../../components/patientComponents/component/form/outlined-inputs/checkbox";
import AddDispenser from "../../../components/patientComponents/component/post-register/addDispenser";
import AddDoctor from "../../../components/patientComponents/component/post-register/addDoctor";
import styles from "../../../styles/patientStyles/Profile.module.css";
import { NotificationManager } from "react-notifications";

const isHealthProgramSelected = (healthProgramSelectedItems, type) => {
  if (!healthProgramSelectedItems) return false;
  return healthProgramSelectedItems.find((x) => x === type) !== undefined;
};

const addWhichPlaceAttention = (place) => {
  return (
    place === PlaceAttentionEnum.ips ||
    place === PlaceAttentionEnum.urologicalClinics
  );
};

const getHealthProgram = (patient) => {
  let healthProgram = [];
  if (patient.epsId) healthProgram.push(HealthProgramTypeEnum.eps);
  if (patient.prepaidMedicineId)
    healthProgram.push(HealthProgramTypeEnum.prepaidMedicine);
  if (patient.supplementalPlanId)
    healthProgram.push(HealthProgramTypeEnum.supplementalPlan);
  if (patient.healthPolicyId)
    healthProgram.push(HealthProgramTypeEnum.healthPolicy);

  return healthProgram;
};

const HealthData = ({ patient, refreshPatient }) => {
  const [doctorId, setDoctorId] = useState([]);
  const [pathologies, setPathologies] = useState([]);
  const [dispenserList, setDispenserList] = useState([]);
  const [entities, setEntities] = useState([]);

  const [epsList, setEpsList] = useState([]);
  const [supplementalPlanList, setSupplementalPlanList] = useState([]);
  const [prepaidMedicinePlanList, setPrepaidMedicinePlanList] = useState([]);
  const [healthPolicyList, setHealthPolicyList] = useState([]);

  const [dlgAddDispenser, setDlgAddDispenser] = useState(false);
  const [dlgAddDoctor, setDlgAddDoctor] = useState(false);

  const defaultValues = {};
  const methods = useForm({ defaultValues });
  const { register, handleSubmit, formState, watch, setValue } = methods;
  const { errors } = formState;

  const registerOptions = {
    regime: { required: "Debe seleccionar un régimen" },
    affiliationType: { required: "Debe seleccionar un tipo de afiliación" },
    supplementalPlan: { required: "Debe seleccionar un Plan complementario" },
    healthProgram: { required: "Debe seleccionar por lo menos un programa" },
    entityId: { required: "Debe seleccionar una entidad" },
    doctorId: { required: "Debe seleccionar un médico" },
    placeAttention: { required: "Debe seleccionar un lugar" },
    pathologyId: { required: "Debe seleccionar una patología" },
    dispenserId: { required: "Debe seleccionar un dispensador" },
    whichPlaceAttention: { required: "Campo obligatorio" },
  };

  const affiliationTypeValue = watch("affiliationType");
  const healthProgramValue = watch("healthProgram");
  const epsValue = watch("eps");
  const supplementalPlanValue = watch("supplementalPlan");
  const prepaidMedicineValue = watch("prepaidMedicine");
  const healthPolicyValue = watch("healthPolicy");
  const entityIdValue = watch("entityId");
  const doctorIdValue = watch("doctorId");
  const placeAttentionValue = watch("placeAttention");
  const pathologyIdValue = watch("pathologyId");
  const dispenserIdValue = watch("dispenserId");
  const regimeValue = watch("regime");
  const isPrivatePurchaseValue = watch("isPrivatePurchase");

  const loadHealthProgramType = (selectedTypes) => {
    const callLoad = (type, setData) => {
      callApi(() => getHealthProgramByType(type), setData);
    };

    if (isHealthProgramSelected(selectedTypes, HealthProgramTypeEnum.eps)) {
      if (epsList.length === 0) callLoad(HealthProgramTypeEnum.eps, setEpsList);
    } else {
      setValue("eps", "");
    }

    if (
      isHealthProgramSelected(
        selectedTypes,
        HealthProgramTypeEnum.supplementalPlan
      )
    ) {
      if (supplementalPlanList.length === 0)
        callLoad(
          HealthProgramTypeEnum.supplementalPlan,
          setSupplementalPlanList
        );
    } else {
      setValue("supplementalPlan", "");
    }

    if (
      isHealthProgramSelected(
        selectedTypes,
        HealthProgramTypeEnum.prepaidMedicine
      )
    ) {
      if (prepaidMedicinePlanList.length === 0)
        callLoad(
          HealthProgramTypeEnum.prepaidMedicine,
          setPrepaidMedicinePlanList
        );
    } else {
      setValue("prepaidMedicine", "");
    }

    if (
      isHealthProgramSelected(selectedTypes, HealthProgramTypeEnum.healthPolicy)
    ) {
      if (healthPolicyList.length === 0)
        callLoad(HealthProgramTypeEnum.healthPolicy, setHealthPolicyList);
    } else {
      setValue("healthPolicy", "");
    }
  };

  const loadData = (healthData) => {
    setValue("healthProgram", getHealthProgram(healthData));
    setValue("regime", healthData.regime + "");
    setValue("affiliationType", healthData.affiliationType);
    setValue("entityId", healthData.entityId);
    setValue("doctorId", healthData.doctorId);
    setValue("placeAttention", healthData.placeAttention);
    setValue("whichPlaceAttention", healthData.whichPlaceAttention);
    setValue("companionName", healthData.companionName);
    setValue("companionPhone", healthData.companionPhone);
    setValue("pathologyId", healthData.pathologyId);
    setValue("dispenserId", healthData.dispenserId);
    setValue("eps", healthData.epsId);
    setValue("supplementalPlan", healthData.supplementalPlanId);
    setValue("prepaidMedicine", healthData.prepaidMedicineId);
    setValue("healthPolicy", healthData.healthPolicyId);
    setValue("isPrivatePurchase", healthData.isPrivatePurchase);
  };

  useEffect(() => {
    callApi(getPreRegisterSelects, (data) => {
      setDoctorId([...data.doctors, { id: -1, name: "Adicionar otro" }]);
      setPathologies(data.pathologies);
      setDispenserList([
        ...data.dispensers,
        { id: -1, name: "Adicionar otro" },
      ]);
      setEntities(data.entities);
    });
    if (patient) loadData(patient);
  }, [patient]);

  useEffect(() => {
    if (healthProgramValue) {
      loadHealthProgramType(healthProgramValue);
      return;
    }
  }, [healthProgramValue]);

  useEffect(() => {
    if (doctorIdValue === -1) setDlgAddDoctor(true);
  }, [doctorIdValue]);

  useEffect(() => {
    if (dispenserIdValue === -1) setDlgAddDispenser(true);
  }, [dispenserIdValue]);

  const onAddDispenser = (dispenser, reset) => {
    callApi(
      () => postAddDispenser(dispenser),
      () => {
        callApi(getDispensers, (data) => {
          const maxId = Math.max(...data.map((x) => x.id));
          setValue("dispenserId", maxId);
          setDispenserList([...data, { id: -1, name: "Adicionar otro" }]);
          setDlgAddDispenser(false);
          reset();
        });
      }
    );
  };

  const onAdddDoctor = (doctor, reset) => {
    callApi(
      () => postAddDoctor(doctor),
      () => {
        callApi(getDoctors, (data) => {
          const maxId = Math.max(...data.map((x) => x.id));
          setValue("doctorId", maxId);
          setDoctorId([...data, { id: -1, name: "Adicionar otro" }]);
          setDlgAddDoctor(false);
          reset();
        });
      }
    );
  };

  const onSubmit = handleSubmit((data) => {
    const healthData = {
      regime: Number(data.regime),
      affiliationType: data.affiliationType,
      healthProgram: data.healthProgram,
      entityId: data.entityId,
      doctorId: data.doctorId,
      placeAttention: data.placeAttention,
      whichPlaceAttention: !addWhichPlaceAttention(placeAttentionValue)
        ? null
        : data.whichPlaceAttention,
      companionName: data.companionName,
      companionPhone: data.companionPhone,
      pathologyId: data.pathologyId,
      dispenserId: data.dispenserId,
      epsId: !data.eps ? null : data.eps,
      supplementalPlanId: !data.supplementalPlan ? null : data.supplementalPlan,
      prepaidMedicineId: !data.prepaidMedicine ? null : data.prepaidMedicine,
      healthPolicyId: !data.healthPolicy ? null : data.healthPolicy,
      isPrivatePurchase: data.isPrivatePurchase,
    };
    callApi(
      () => patientUpdateHealth(healthData),
      () => {
        NotificationManager.success("La operación se realizó correctamente");
        refreshPatient();
      }
    );
  });

  return (
    <div>
      <Form>
        <RadioOutlinedInput
          name="regime "
          label="Por favor seleccione su régimen"
          row
          options={RegimeList()}
          required
          register={register}
          registerOptions={registerOptions}
          errors={errors}
          onChange={(e) => setValue("regime", e.target.value)}
          value={regimeValue ?? ""}
        />

        <MarginSeparator />
        <MarginSeparator />

        <Autocomplete
          name="affiliationType"
          label="Seleccione su tipo de afiliación"
          placeholder="Seleccione su tipo de afiliación"
          register={register}
          options={AffiliationTypeList()}
          required
          errors={errors}
          watch={watch}
          setValue={setValue}
        />

        <Autocomplete
          registerOptions={registerOptions}
          name="healthProgram"
          label="Seleccione su programa en salud"
          register={register}
          placeholder="Seleccione su programa en salud"
          multiple
          options={HealthProgramTypeList()}
          required
          errors={errors}
          watch={watch}
          setValue={setValue}
        />
        {isHealthProgramSelected(
          healthProgramValue,
          HealthProgramTypeEnum.eps
        ) && (
          <Autocomplete
            name="eps"
            label="EPS"
            placeholder="Seleccione su EPS"
            options={epsList}
            required
            register={register}
            registerOptions={registerOptions}
            errors={errors}
            watch={watch}
            setValue={setValue}
          />
        )}
        {isHealthProgramSelected(
          healthProgramValue,
          HealthProgramTypeEnum.supplementalPlan
        ) && (
          <Autocomplete
            name="supplementalPlan"
            label="Plan complementario"
            placeholder="Seleccione su Plan complementario"
            options={supplementalPlanList}
            required
            register={register}
            registerOptions={registerOptions}
            errors={errors}
            watch={watch}
            setValue={setValue}
          />
        )}
        {isHealthProgramSelected(
          healthProgramValue,
          HealthProgramTypeEnum.prepaidMedicine
        ) && (
          <Autocomplete
            register={register}
            registerOptions={registerOptions}
            name="prepaidMedicine"
            label="Medicina prepagada"
            placeholder="Seleccione una opción"
            options={prepaidMedicinePlanList}
            required
            errors={errors}
            watch={watch}
            setValue={setValue}
          />
        )}
        {isHealthProgramSelected(
          healthProgramValue,
          HealthProgramTypeEnum.healthPolicy
        ) && (
          <Autocomplete
            register={register}
            registerOptions={registerOptions}
            name="healthPolicy"
            label="Póliza en salud"
            placeholder="Seleccione una opción"
            options={healthPolicyList}
            required
            errors={errors}
            watch={watch}
            setValue={setValue}
          />
        )}
        <Autocomplete
          name="entityId"
          label="Seleccione la entidad que le prescribió"
          placeholder="Seleccione la entidad que le prescribió *"
          options={entities}
          required
          register={register}
          registerOptions={registerOptions}
          errors={errors}
          watch={watch}
          setValue={setValue}
        />

        <Autocomplete
          name="doctorId"
          label="Seleccione el médico que le formuló"
          placeholder="Seleccione el médico que le formuló *"
          options={doctorId}
          required
          register={register}
          registerOptions={registerOptions}
          errors={errors}
          watch={watch}
          setValue={setValue}
        />

        <Autocomplete
          name="placeAttention"
          label="Seleccione dónde le atendió"
          placeholder="Seleccione dónde le atendió *"
          options={PlaceAttentionList()}
          required
          register={register}
          registerOptions={registerOptions}
          errors={errors}
          watch={watch}
          setValue={setValue}
        />

        {!placeAttentionValue ||
          (addWhichPlaceAttention(placeAttentionValue) && (
            <TextOutlinedInput
              name="whichPlaceAttention"
              label="¿Cuál?"
              type={"text"}
              register={register}
              registerOptions={registerOptions}
              required
              errors={errors}
            />
          ))}

        <TextOutlinedInput
          name="companionName"
          label="Ingrese el nombre del acompañante"
          type={"text"}
          register={register}
        />

        <TextOutlinedInput
          name="companionPhone"
          label="Ingrese el teléfono del acompañante"
          type={"text"}
          register={register}
        />

        <Autocomplete
          name="pathologyId"
          label="Seleccione su patología"
          placeholder="Seleccione su patología *"
          register={register}
          options={pathologies}
          required
          errors={errors}
          watch={watch}
          setValue={setValue}
        />

        <Autocomplete
          name="dispenserId"
          label="Dispensador de tratamiento"
          placeholder="Dispensador de tratamiento *"
          register={register}
          options={dispenserList}
          required
          errors={errors}
          watch={watch}
          setValue={setValue}
        />
        <CheckboxOutlinedInput
          id="isPrivatePurchase"
          name="isPrivatePurchase"
          register={register}
          registerOptions={registerOptions}
          errors={errors}
          value={isPrivatePurchaseValue == true ? true : false}
          checked={isPrivatePurchaseValue == true ? true : false}
          onChange={(e) => setValue("isPrivatePurchase", e.target.checked)}
        >
          ¿Es compra particular?
        </CheckboxOutlinedInput>

        <button className={styles.button} onClick={onSubmit}>
          <p className={styles.buttonContent}>Actualizar perfil</p>
        </button>
      </Form>
      <AddDispenser
        open={dlgAddDispenser}
        onClose={() => {
          setDlgAddDispenser(false);
          setValue("dispenserId", patient.dispenserId);
        }}
        onAddDispenser={onAddDispenser}
      />
      <AddDoctor
        open={dlgAddDoctor}
        onClose={() => {
          setDlgAddDoctor(false);
          setValue("doctorId", patient.doctorId);
        }}
        onAddDoctor={onAdddDoctor}
      />
    </div>
  );
};

export default HealthData;
