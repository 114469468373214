import React from 'react'
import { Link } from "react-router-dom";
import ModalPWAPatient from '../../../components/patientComponents/component/modalPWA';
import {
    Container,
    InnerContainer,
    HeaderLogo,
    Logo,
    Title,
    Text,
    LoginButton,
    RegisterButton,
} from '../../../components/patientComponents/styled-components/styledWelcome'

const PatientMain = () => {
    return (
        <div>
            <HeaderLogo>
                <InnerContainer>
                    <Logo src="/patient/logo-white.svg" alt="ialuriApp Logo" />
                    <Title>Su aliado en el tratamiento con ialuril ® Prefill</Title>
                </InnerContainer>
            </HeaderLogo>

            <Container>
                <InnerContainer>
                    <Text>
                        Esta app ha sido construida con el fin de aportar información acerca
                        del seguimiento a la evolución de su sintomatología en el
                        tratamiento con <strong>ialuril ® Prefill</strong>
                    </Text>

                    <LoginButton>
                        <Link to="/patient/login">Iniciar sesión</Link>
                    </LoginButton>

                    <RegisterButton>
                        <Link to="/patient/register">Registrarse</Link>
                    </RegisterButton>
                </InnerContainer>
            </Container>
            <ModalPWAPatient />
        </div>
    )
}

export default PatientMain
