import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import styles from '../../../../styles/patientStyles/components/ModalButtons.module.css'

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'black,',
    '& .MuiDialog-paper': {
      margin: '16px',
      width: '100%',
    },
    '& .MuiDialogContent-root': {
      padding: '10px 10px 40px 10px',
    },
    '& .MuiTypography-h6': {
      fontFamily: ' Source Sans Pro',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '22px',
      lineHeight: '28px',
      padding: '20px 0 0 10px',
      textAlign: 'center',
      color: '#343A40',
    },
  },
  modalLabel: {
    width: '100%',
    maxWidth: '290px',
    margin: '0 auto 0 auto',
  },
}))

const Contact = ({ open, onClose, onAddSpecialty }) => {
  const classes = useStyles()

  return (
    <Dialog
      className={classes.root}
      open={open}
      keepMounted
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        <img src="/patient/contactFrame.svg" />
      </DialogTitle>
      <DialogContent>
        <div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img style={{ padding: '0 10px 0 0' }} src="/patient/contactFrame1.svg" />
            <p className={styles.text}>Alba Amaya</p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img style={{ padding: '0 10px 0 0' }} src="/patient/contactFrame2.svg" />
            <p className={styles.text}>310-815-3352</p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img style={{ padding: '0 10px 0 0' }} src="/patient/contactFrame3.svg" />
            <p className={styles.text}>info@paap.com</p>
          </div>
          <div
            style={{
              margin: '50px 0 0 0',
              display: 'flex',
              justifyContent: 'center',
            }}
            className={'div-horizontal'}
          >
            <button
              style={{ margin: '0', cursor: "pointer" }}
              className={styles.confirmButtonB}
              onClick={onClose}
            >
              <p className={styles.buttonContentB}>OK</p>
            </button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default Contact
