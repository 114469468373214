import React from 'react'
import styles from '../../../../styles/patientStyles/components/RecommendationCard.module.css'

const RecommendationCard2 = () => {
  return (
    <>
      <div className={styles.recommendationBox1}>
        <div className={styles.recommendationImgBox}>
          <div style={{
            height: "70px",
            display: "flex"
          }} className={styles.numberBox}>
            <p className={styles.number}>
            <b className={styles.whiteB}>1</b>
            </p>
          </div>
          <div className={`${styles.imgBox2}`}>
            <img
              className={`${styles.imgRecomendation1}`}
              src="/patient/recomendaciones-art2-01.png"
              alt="Info"
            />
          </div>
        </div>
        <div>
          <p className={styles.colorText}>
            Realizar aseo genital{' '}
            <b className={`${styles.b}`}>únicamente con agua y jabón</b>
          </p>
        </div>
      </div>

      <div className={styles.recommendationBox2}>
        <div className={styles.recommendationImgBox2}>
          <div className={`${styles.imgBox2}`}>
            <img
              className={`${styles.imgRecomendation1}`}
              src="/patient/recomendaciones-art2-02.png"
              alt="Info"
            />
          </div>
          <div style={{
            height: "70px",
            display: "flex"
          }} className={styles.numberBox2}>
            <p className={styles.number2}>
            <b className={styles.purpleB}>2</b>
            </p>
          </div>
        </div>
        <div>
          <p className={styles.whiteText}>
            Recuerde que{' '}
            <b className={`${styles.wb}`}>no es necesario que acuda en ayunas</b>
          </p>
        </div>
      </div>

      <div className={styles.recommendationBox5}>
        <div className={styles.recommendationImgBox}>
          <div style={{
            height: "70px",
            display: "flex"
          }} className={styles.numberBox}>
            <p className={styles.number}>
            <b className={styles.whiteB}>3</b>
            </p>
          </div>
          <div className={`${styles.imgBox2}`}>
            <img
              className={`${styles.imgRecomendation1}`}
              src="/patient/recomendaciones-art2-03.png"
              alt="Info"
            />
          </div>
        </div>
        <div>
          <p className={styles.colorText}>
            Asistir <b className={`${styles.b}`}>15 minutos antes</b> de la hora
            programada
          </p>
        </div>
      </div>

      <div className={styles.recommendationBox2}>
        <div className={styles.recommendationImgBox2}>
          <div className={`${styles.imgBox2}`}>
            <img
              className={`${styles.imgRecomendation1}`}
              src="/patient/recomendaciones-art2-04.png"
              alt="Info"
            />
          </div>
          <div style={{
            height: "70px",
            display: "flex"
          }} className={styles.numberBox2}>
            <p className={styles.number2}>
            <b className={styles.purpleB}>4</b>
            </p>
          </div>
        </div>
        <div>
          <p className={styles.whiteText}>
            No olvide llevar consigo:{' '}
            <b className={`${styles.wb}`}>
              orden médica, historia clínica, documento de identificación y
              autorización
            </b>
          </p>
        </div>
      </div>

      <div className={styles.recommendationBox5}>
        <div className={styles.recommendationImgBox}>
          <div style={{
            height: "70px",
            display: "flex"
          }} className={styles.numberBox}>
            <p className={styles.number}>
            <b className={styles.whiteB}>5</b>
            </p>
          </div>
          <div className={`${styles.imgBox2}`}>
            <img
              className={`${styles.imgRecomendation1}`}
              src="/patient/recomendaciones-art2-05.png"
              alt="Info"
            />
          </div>
        </div>
        <div>
          <p className={styles.colorText}>
            Le recomendamos{' '}
            <b className={`${styles.b}`}>no llevar objetos de valor</b>
          </p>
        </div>
      </div>

      <div className={styles.recommendationBox2}>
        <div className={styles.recommendationImgBox2}>
          <div className={`${styles.imgBox2}`}>
            <img
              className={`${styles.imgRecomendation1}`}
              src="/patient/recomendaciones-art2-06.png"
              alt="Info"
            />
          </div>
          <div style={{
            height: "70px",
            display: "flex"
          }} className={styles.numberBox2}>
            <p className={styles.number2}>
            <b className={styles.purpleB}>6</b>
            </p>
          </div>
        </div>
        <div>
          <p className={styles.whiteText}>
            Antes de ingresar al procedimiento debe{' '}
            <b className={`${styles.wb}`}>evacuar la vejiga</b>
          </p>
        </div>
      </div>

      <div className={styles.recommendationBox5}>
        <div className={styles.recommendationImgBox}>
          <div style={{
            height: "70px",
            display: "flex"
          }} className={styles.numberBox}>
            <p className={styles.number}>
            <b className={styles.whiteB}>7</b>
            </p>
          </div>
          <div className={`${styles.imgBox2}`}>
            <img
              className={`${styles.imgRecomendation1}`}
              src="/patient/recomendaciones-art2-07.png"
              alt="Info"
            />
          </div>
        </div>
        <div>
          <p className={styles.colorText}>
            Si la IPS requiere que lleve consigo el producto ialuril ®Prefill,{' '}
            <b className={`${styles.b}`}>no olvide llevarlo</b>
          </p>
        </div>
      </div>

      <div className={styles.recommendationBox4}>
        <div className={styles.recommendationImgBox2}>
          <div className={`${styles.imgBox2}`}>
            <img
              className={`${styles.imgRecomendation1}`}
              src="/patient/recomendaciones-art2-08.png"
              alt="Info"
            />
          </div>
          <div style={{
            height: "70px",
            display: "flex"
          }} className={styles.numberBox2}>
            <p className={styles.number2}>
            <b className={styles.purpleB}>8</b>
            </p>
          </div>
        </div>
        <div>
          <p className={styles.whiteText}>
            Recuerde antes de salir{' '}
            <b className={`${styles.wb}`}>programar su siguiente instilación</b>,
            para no interrumpir el cliclo de tratamiento
          </p>
        </div>
      </div>
    </>
  )
}

export default RecommendationCard2
