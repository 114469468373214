import React, { useState, useEffect } from 'react'
import { Form } from '../../../components/patientComponents/styled-components/profile/styled'
import { useForm } from 'react-hook-form'
import ButtonAsync from '../../../components/patientComponents/component/ButtonAsync/index'
import {
  callApi,
  callApiAsync,
  getCyties,
  getStateProvinces,
  patientUpdateContact,
} from '../../../services/patientServices/apiList'
import Autocomplete from '../../../components/patientComponents/component/form/outlined-inputs/autocomplete'
import TextOutlinedInput from '../../../components/patientComponents/component/form/outlined-inputs/text'
import styles from '../../../styles/patientStyles/Profile.module.css'
import { NotificationManager } from 'react-notifications'

const Contact = ({ patient, refreshPatient }) => {
  const [stateProvinces, setStateProvinces] = useState([])
  const [cities, setCities] = useState([])

  const defaultValues = {}
  const methods = useForm({ defaultValues })
  const { register, handleSubmit, formState, watch, setValue } = methods
  const { errors } = formState

  const departmentValue = watch('department')
  const cityIdValue = watch('cityId')

  const registerOptions = {
    department: { required: 'Debes seleccionar un departamento' },
    cityId: { required: 'Debes seleccionar una ciudad' },
    address: { required: 'Por favor ingrese su dirección' },
    phone: { required: 'Por favor ingresa un numero de telefono' },
    cellPhone: { required: 'Por favor ingresa un numero de celular' },
  }

  const loadData = (contact) => {
    setValue('department', contact.stateProvinceId)
    setValue('cityId', contact.cityId)
    setValue('address', contact.address)
    setValue('phone', contact.phone)
    setValue('cellPhone', contact.cellPhone)
  }

  useEffect(() => {
    if (patient) loadData(patient)
  }, [patient])

  useEffect(() => {
    callApi(getStateProvinces, setStateProvinces)
  }, [])

  useEffect(() => {
    if (!departmentValue) {
      setCities([])
      return
    }
    callApi(() => getCyties(departmentValue), setCities)
  }, [departmentValue])

  const onSubmit = handleSubmit(async (data) => {
    const contact = {
      cityId: data.cityId,
      address: data.address,
      phone: data.phone,
      cellPhone: data.cellPhone,
    }
    await callApiAsync(
      () => patientUpdateContact(contact),
      () => {
        NotificationManager.success('La operación se realizó correctamente')
        refreshPatient()
      }
    )
  })

  return (
    <div>
      <Form>
        <Autocomplete
          name="department"
          label="Seleccione su departamento"
          placeholder="Seleccione su departamento"
          register={register}
          registerOptions={registerOptions}
          options={stateProvinces}
          required
          errors={errors}
          watch={watch}
          setValue={setValue}
        />

        <Autocomplete
          name="cityId"
          label="Seleccione su ciudad"
          placeholder="Seleccione su ciudad"
          register={register}
          registerOptions={registerOptions}
          options={cities}
          required
          errors={errors}
          watch={watch}
          setValue={setValue}
        />

        <TextOutlinedInput
          name="address"
          label="Dirección"
          type={'text'}
          required
          register={register}
          registerOptions={registerOptions}
          errors={errors}
        />

        <TextOutlinedInput
          name="phone"
          label="Numero de telefono"
          type={'text'}
          required
          register={register}
          registerOptions={registerOptions}
          errors={errors}
        />

        <TextOutlinedInput
          name="cellPhone"
          label="Numero de Celular"
          type={'text'}
          required
          register={register}
          registerOptions={registerOptions}
          errors={errors}
        />

        <ButtonAsync className={styles.button} onClick={onSubmit}>
          <p className={styles.buttonContent}>Actualizar perfil</p>
        </ButtonAsync>
      </Form>
    </div>
  )
}

export default Contact
