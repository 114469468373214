import React from "react";
import { useHistory, Link } from "react-router-dom";
import Button1 from "../../../components/doctorComponents/buttons/button-1";
import ModalPWA from "../../../components/doctorComponents/modalPWA/index";

const Message = () => {
  let history = useHistory();

  return (
    <>
      <div className="container">
        <div className="inner-container">
          <img
            className="ialurilLogoWhite2"
            src="/doctor/logo-white-menu.svg"
            alt="IalurilLogoWhite"
          />
          <p className="white-title">Estimado doctor (a)</p>

          <p className="white-font-1">
            Al igual que para usted, el objetivo de nuestra labor son los
            pacientes, por ese motivo hemos desarrollado la aplicación ialuril
            App.
          </p>
          <p className="white-font-1">
            Esta nueva plataforma busca informar y acompañar al especialista y
            sus pacientes proporcionándoles herramientas que permitan al
            paciente un mejor entendimiento, seguimiento y cuidado de su
            enfermedad empoderándolos en el manejo de la misma.
          </p>
          <p className="white-font-1">
            Queremos invitarlo a conocer ialuril App y a compartirla con sus
            pacientes, cuidadores y otros profesionales de la salud y así
            permitirnos acompañarlos en la educación de sus pacientes y el
            seguimiento permanente.
          </p>
          <p className="white-font-1">
            El contenido que usted y sus pacientes encontrara en Ialuril App no
            es promocional.
          </p>
          <p className="white-font-1">
            Este mensaje está dirigido exclusivamente a profesionales de la
            salud en Colombia.
          </p>

          <p className="white-font-1">
            Todo evento adverso debe ser notificado. El formulario de
            notificación e información se encuentra disponible en
            <a href="https://www.seranestpharma.com/index.php/tecnovigilancia" target="_blank"> tecnovigilancia</a>
            . Los
            eventos adversos también pueden ser notificados a
            servicioalcliente@seranestpharma.com.
          </p>
          <p className="white-font-1">
            Solicite mayor información científica de nuestros productos en
            nuestro servicio de información médica, a través del email:
            asesoriamedica@seranestpharma.com.
          </p>
          <br />
          <br />

          <div className="div-center">
            <Button1 onClick={() => history.push("/doctor/login")} label="Iniciar sesión" />
            <br />

            <p className="white-font-1">
              ¿No tiene una cuenta?{" "}
              <Link to="/doctor/register" className="white-font-underline">
                Regístrese
              </Link>
            </p>
            <br />
            <br />
          </div>
        </div>
      </div>
      <ModalPWA />
    </>
  );
};

export default Message;
