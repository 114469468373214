import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import styles from '../../../styles/patientStyles/Survey.module.css'
import RadioOutlinedInput from './form/outlined-inputs/radio'

const QuestionCheck = ({
  label,
  options,
  questionNumber,
  survey,
  isNextClick,
  onNextClick,
  setShowEndButton,
  instillationNumber,
}) => {
  const name = 'question' + questionNumber

  const defaultValues = {}
  const methods = useForm({ defaultValues })
  const { register, formState, getValues, trigger, watch, setValue } = methods
  const { errors } = formState

  const itemValue = watch(name)

  const registerOptions = {}
  registerOptions[name] = { required: 'Por favor seleccione una opción' }

  const saveAsync = async () => {
    const isOk = await trigger()
    if (!isOk) {
      onNextClick(false)
      return
    }

    const d = getValues()
    const obj = { ...survey }
    if (options[0].label === 'Si') obj[name] = d[name] === '1'
    else obj[name] = Number(d[name])

    onNextClick(obj)
  }

  const loadData = () => {
    // console.log("survey",survey);
    // //setValue("q", "1");
    // return;
    // if(survey && (survey.question1 || survey.question1 === false))
    //   setValue("q", survey.question1 ? '1' : '0');
  }

  useEffect(() => {
    if (survey) setTimeout(loadData, 500)
  }, [survey])

  useEffect(async () => {
    if (isNextClick) await saveAsync()
  }, [isNextClick])

  const updateButton = (e) => {
    if(instillationNumber ==6){
      if (questionNumber === '7') {
        setShowEndButton(true)
      }else{
        setShowEndButton(false);
      }
    }else{
      if (questionNumber === '6') {
        setShowEndButton(true)
      }else{
        setShowEndButton(false);
      }
    }
  }
  return (
    <div>
      <p className={styles.question}>{label}</p>
      <RadioOutlinedInput
        id={name}
        name={name}
        label=""
        options={options}
        register={register}
        registerOptions={registerOptions}
        errors={errors}
        onChange={(e) => updateButton(e)}
        value={itemValue ?? ''}
      />
    </div>
  )
}

export default QuestionCheck
