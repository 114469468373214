import React, { useState, useEffect } from 'react'
import {
  HeaderLogo,
  InnerContainer,
  Logo,
  Title,
} from '../../../components/patientComponents/styled-components/register/styled'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import CallOutlinedIcon from '@material-ui/icons/CallOutlined'
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined'
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined'
import { createMuiTheme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import styles from '../../../styles/patientStyles/Profile.module.css'
import Information from './information'
import Footer from '../../../components/patientComponents/component/footer'
import Contact from './contact'
import HealthData from './healthData'
import HeaderWithMenu from '../../../components/patientComponents/component/headerWithMenu'
import { callApi, getPatient } from '../../../services/patientServices/apiList'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
}))

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#b16db3',
    },
  },
})

const PatientProfile = () => {
  const classes = useStyles()
  const [value, setValue] = useState(0)
  const [patient, setPatient] = useState(null)

  const loadPatient = () => callApi(getPatient, setPatient)

  useEffect(() => loadPatient(), [])

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div>
      <HeaderWithMenu />
      <HeaderLogo>
        <InnerContainer>
          <br></br>
          <br></br>
          <Title>Perfil de usuario</Title>
          <p className={styles.subTitle}>
            A continuación podrá modificar los campos habilitados para
            actualizar su perfil
          </p>
        </InnerContainer>
      </HeaderLogo>

      <>
        <div className={styles.container}>
          <div className={styles.menu}>
            <Tabs
              className={classes.root}
              value={value}
              onChange={handleChange}
              variant="scrollable"
              indicatorColor="primary"
              textColor="primary"
              aria-label="scrollable force tabs example"
            >
              <Tab
                label="Información"
                className={styles.tabWrapper}
                icon={<PersonOutlineOutlinedIcon />}
                {...a11yProps(0)}
              />
              <Tab
                label="Contacto"
                className={styles.tabWrapper}
                icon={<CallOutlinedIcon />}
                {...a11yProps(1)}
              />
              <Tab
                label="Datos en salud"
                className={styles.tabWrapper}
                icon={<AddBoxOutlinedIcon />}
                {...a11yProps(2)}
              />
            </Tabs>
          </div>
          <TabPanel value={value} index={0}>
            <Information patient={patient} refreshPatient={loadPatient} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Contact patient={patient} refreshPatient={loadPatient} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <HealthData patient={patient} refreshPatient={loadPatient} />
          </TabPanel>
        </div>
      </>
      <Footer />
    </div>
  )
}

export default PatientProfile
