import React from "react";

import AccordionComponent from "../../../../components/doctorComponents/accordion/accordion-1";
import AccordionBibliography from "../../../../components/doctorComponents/accordion/accordion-2";

const AboutIaluril = () => {
  return (
    <div className="container">
      <div className="inner-container">
        <div className="container-horizontal-img">
          <div
            style={{ width: "100%", display: "flex", flexDirection: "column" }}
          >
            <img
              className="img"
              src="/doctor/ialuril-prefill-caja-xxhdpi.png"
              alt="Img"
            />
            <p style={{ alignSelf: "flex-end", margin: "0 25px 0 0" }}>
              Registro Invima 2014DM-0012306
            </p>
          </div>
          <div
            style={{ width: "100%", display: "flex", flexDirection: "column" }}
          >
            <img
              className="img"
              src="/doctor/ialuril-prefill-jeringa-hdpi.png"
              alt="Img"
            />
             <p style={{ alignSelf: "flex-end", margin: "0 25px 0 0" }}>
              Registro Invima 2014DM-0012306
            </p>
          </div>
        </div>

        <p>
          <b>ialuril ® Prefill</b> es la nueva era en el tratamiento de cistitis
          crónica y dolor pélvico asociado. Sus altas concentraciones de
          glucosaminoglicanos Sal sódica de ácido hialurónico (1.6%) 800mg,
          Sulfato de condroitina sódica (2.0%) 1g y cloruro de calcio (0.87%)
          440 mg, son el complemento ideal para lograr{" "}
          <b>la efectividad en la restauración de la capa del urotelio</b>, con
          múltiples beneficios como son:{" "}
        </p>
        <ul>
          <li>
            {" "}
            Impide la adherencia bacteriana al urotelio.
            <sup>
              <b>1</b>
            </sup>
          </li>
          <li>
            {" "}
            Reducción de la inflamación crónica.
            <sup>
              <b>1,2</b>
            </sup>
          </li>
          <li>
            {" "}
            Mejoría de la permeabilidad urotelial.
            <sup>
              <b>2,3,4,5,6,7</b>
            </sup>
          </li>
          <li>
            {" "}
            Aumento en la capacidad cistométrica.
            <sup>
              <b>8</b>
            </sup>
          </li>
          <li>
            {" "}
            Aumento del volumen urinario.
            <sup>
              <b>4</b>
            </sup>
          </li>
          <li>
            {" "}
            Reduce la urgencia urinaria.
            <sup>
              <b>4,5</b>
            </sup>
          </li>
          <li> Reduce la nicturia.</li>
          <li>
            {" "}
            Reduce el dolor y molestias pélvico-vesicales.
            <sup>
              <b>2,9</b>
            </sup>
          </li>
          <li>
            {" "}
            Alternativa no antibiótica.
            <sup>
              <b>4,10</b>
            </sup>
          </li>
          <li>
            {" "}
            Sin efectos secundarios sistémicos.
            <sup>
              <b>11,12,13</b>
            </sup>
          </li>
        </ul>

        <img
          className="img"
          src="/doctor/ialuril-prefill-alta-efectividad-xhdpi.png"
          alt="Img"
        />

        <AccordionComponent article="about-ialuril" title="INDICADO EN TODO TIPO DE CISTITIS">
          <div>
            <ul>
              <li> Cistitis bacteriana recurrente.</li>
              <li> Cistitis intersticial y síndrome de vejiga dolorosa.</li>
              <li> Cistitis hemorrágica inducida por BCG y posradiación.</li>
              <li> Cistitis actínica .</li>
            </ul>

            <img
              className="img"
              src="/doctor/doctor-img020.png"
              alt="Img"
            />
          </div>
        </AccordionComponent>

        <AccordionComponent article="about-ialuril" title="MODO DE USO">
        <div className="container-horizontal-img" style={{ width: "auto" }}>
        <div
            style={{ width: "100%", display: "flex", flexDirection: "column" }}
          >
            <img
              className="img"
              src="/doctor/modo-de-uso-ialuril-prefill-xhdpi.png"
              alt="Img"
            />
              <p style={{ alignSelf: "flex-end", margin: "0 25px 0 0" }}>
              Registro Invima 2014DM-0012306
            </p>
          </div>
          </div>
        </AccordionComponent>

        <AccordionComponent article="about-ialuril" title="EN EL MOMENTO DE FORMULAR TENGA EN CUENTA">
          <div>
            <div className="container-horizontal-img" style={{ width: "auto" }}>
              <img
                style={{ width: "auto" }}
                className="img"
                src="/doctor/doctor-img010.png"
                alt="Img"
              />
            </div>
          </div>
        </AccordionComponent>

        <AccordionBibliography title="BIBLIOGRAFIA">
          <div>
            <p className="bibliography-font">
              {" "}
              <b>1.</b> Cervigni, M. Interstitial cystitis/bladder pain syndrome
              and glycosaminoglycans replacement therapy. Trans. Androl. Uro.
              2015;14:638.
            </p>
            <p className="bibliography-font">
              <b>2.</b> Gacci M, et al. Bladder instillation therapy with
              hyaluronic acid and chondroitin sulfate improves symptoms of
              postradiation cystitis: Prospective pilot study. Clin Genitourin
              Cancer. 2016; 14(5):444-449.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>3.</b> De Vita D, et al. Long term efficacy of intravesical
              instillation of hyaluronic acid/chondroitin sulfate in recurrent
              bacterial cystitis: 36 months' follow-up. Clinical and
              experimental obstetrics & gynecology. 2018; 45(2):224-228.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>4.</b> De Vita D, Giordano S. Effectiveness of intravesical
              hyaluronic acid/chondroitin sulfate in recurrent bacterial
              cystitis: a randomized study. Int Urogynecol. J. 2012; 23:1707.
            </p>
            <p className="bibliography-font">
              <b>5.</b> Cicione MD, et al. Intravesical treatment with
              highly-concentrated hyaluronic acid and chondroitin sulphate in
              patients with recurrent urinary tract infections: Results from a
              multicentre survey. Can Urol Assoc J. 2014; 8(9-10): e721-727.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>6.</b> Ciani O, et al. Intravesical administration of combined
              hyaluronic acid (HA) and chondroitin sulfate (CS) for the
              treatment of female recurrent urinary tract infections: a European
              multicentre nested case-control study. B.M.J, Open. 2016; 6:
              e009669.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>7.</b> Sherif H, et al. Safety and efficacy of intravesical
              hyalronic acid/chondroitin sulfate in the treatment of refractory
              painful bladder syndrome. Turk J Urol. 2018.
            </p>
            <p className="bibliography-font">
              <b>8.</b> Sommariva ML, et al. Intravesical hyaluronic acid and
              chondroitin sulphate improve symptoms and quality of life in
              patients with late radiation tissue cystitis: An investigative
              pilot study. Eur J Inflammation. 2014;12:177-185.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>9.</b> Giannessi C, et al. Nocturia related to post radiation
              bladder pain can be improved by hyaluronic acid chondroitin
              sulphate (ialuril ® Prefill). Eur Urol Suppl. 2014;13(1): e592-e592a.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>10.</b> Janssen, D. Is clinical practice aligned with the
              latest scientific evidence on GAG therapy?. Urologia. 2017; 84
              (Suppl 1): S16-S20.
            </p>
            <p className="bibliography-font">
              <b>11.</b> Damiano R, et al. Prevention of recurrent urinary tract
              infections by intravesical administration of hyaluronic acid and
              chondroitin sulfate: a placebo-controlled randomised trial. Eur.
              Urol. 2011; 59:645.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>12.</b> Giberti C, et al. Combined intravesical sodium
              hyaluronate/chondroitin sulfate therapy for interstitial
              cystitis/bladder pain syndrome: a prospective study. Ther Adv
              Urol. 2013; 5(4):175-179.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>13.</b> Imperatore V, et al. Intravesical administration of
              combined hyaluronic acid and chondroitin sulfate can improve
              symptoms in patients with refractory bacillus
              Calmette-Guerin-induced chemical cystitis: Preliminary experience
              with one-year follow-up. Archivio italiano di urologia,
              andrologia: organo ufficiale [di] Società italiana di ecografia
              urologica e nefrologica / Associazione ricerche in urologia. 2018;
              90(1):11.
            </p>
          </div>
        </AccordionBibliography>
      </div>
    </div>
  );
};

export default AboutIaluril;
