import React, { useEffect, useState } from 'react'
import { Form, MarginSeparator } from '../../../components/patientComponents/styled-components/profile/styled'
import { useForm } from 'react-hook-form'
import { callApi, patientUpdateInformation } from '../../../services/patientServices/apiList'
import DateOutlinedInput from '../../../components/patientComponents/component/form/outlined-inputs/dateWithoutRegister'
import SelectOutlinedInput from '../../../components/patientComponents/component/form/outlined-inputs/select'
import TextOutlinedInput from '../../../components/patientComponents/component/form/outlined-inputs/text'
import RadioOutlinedInput from '../../../components/patientComponents/component/form/outlined-inputs/radio'
import { DocumentTypeList, GenreList, YesNoList } from '../../../constants/patienteConstants/enums'
import dayjs from 'dayjs'
import styles from '../../../styles/patientStyles/Profile.module.css'
import NewPassword from './newPassword'
import { NotificationManager } from 'react-notifications'

const Information = ({ patient, refreshPatient }) => {
  const defaultValues = {}
  const methods = useForm({ defaultValues })
  const { register, handleSubmit, formState, watch, setValue } = methods
  const { errors } = formState

  const documentTypeValue = watch('documentType')
  const genreValue = watch('genre')
  const permissionNotificationValue = watch('permissionNotification')
  const birthdayValue = watch('birthday')

  const registerOptions = {
    name: { required: 'Debes ingresar tu nombre' },
    lastName: { required: 'Debes ingresar tu nombre' },
    documentType: { required: 'Por favor selecciona un tipo de documento' },
    document: {
      required: 'Por favor ingresa un número de identificación',
    },
    genre: { required: 'Por favor seleciona un género' },
    birthday: { required: 'Por favor ingresa tu fecha de nacimiento' },
    email: { required: 'Debes ingresar tu correo electronico' },
  }

  const loadData = (information) => {
    console.log(information)
    setValue('name', information.name)
    setValue('lastname', information.lastname)
    setValue('documentType', information.documentType)
    setValue('genre', information.genre + '')
    setValue('birthday', dayjs(information.birthday))
    setValue('permissionNotification', information.permissionNotification + '')
  }

  useEffect(() => {
    if (patient) loadData(patient) //setTimeout(() => loadData(patient), 300)
  }, [patient])

  const onSubmit = handleSubmit((data) => {
    const information = {
      name: data.name,
      lastname: data.lastname,
      genre: Number(data.genre),
      birthday: data.birthday,
      notificationPermission:data.permissionNotification=='true'?true:false,
    }
    console.log("Enviar",information);
    const password = {
      password: data.password,
      newPassword: data.newPassword,
      confirmPassword: data.confirmPassword,
    }
    callApi(
      () => patientUpdateInformation(information),
      () => {
        NotificationManager.success('La operación se realizó correctamente')
        refreshPatient()
      }
    )
  })

  return (
    <div>
      <Form>
        <TextOutlinedInput
          name="name"
          label="Nombre"
          type={'text'}
          required
          register={register}
          registerOptions={registerOptions}
          errors={errors}
          value={watch('name') ?? ''}
        />

        <TextOutlinedInput
          name="lastname"
          label="Apellidos"
          type={'text'}
          register={register}
          registerOptions={registerOptions}
          errors={errors}
          value={watch('lastname') ?? ''}
        />

        <SelectOutlinedInput
          name="documentType"
          label="Tipo de Identificación"
          options={DocumentTypeList()}
          required
          register={register}
          registerOptions={registerOptions}
          errors={errors}
          value={documentTypeValue ?? ''}
          disabled
        />

        <TextOutlinedInput
          name="document"
          label="Numero de Documento"
          type={'text'}
          register={register}
          value={patient ? patient.document ?? '' : ''}
          disabled
        />

        <RadioOutlinedInput
          name="genre"
          label="Por favor seleccione su género"
          row
          options={GenreList()}
          required
          register={register}
          registerOptions={registerOptions}
          errors={errors}
          onChange={(e) => setValue('genre', e.target.value)}
          value={genreValue ?? ''}
        />

        <DateOutlinedInput
          name="birthday"
          label="Fecha de Nacimiento"
          register={register}
          defaultValue={null}
          registerOptions={registerOptions}
          errors={errors}
          disableFuture={true}
          onChange={(date) => setValue('birthday', date)}
          value={birthdayValue ?? null}
        />

        <MarginSeparator />
        <MarginSeparator />

        <TextOutlinedInput
          name="email"
          label="Correo electrónico"
          register={register}
          value={patient ? patient.email ?? '' : ''}
          disabled
        />
         <RadioOutlinedInput
          name="permissionNotification"
          label="Desea recibir notificaciones"
          row
          options={YesNoList()}
          register={register}
          registerOptions={registerOptions}
          errors={errors}
          onChange={(p) => setValue('permissionNotification', p.target.value)}
          value={permissionNotificationValue ?? ''}
        />
        <NewPassword />
        <button className={styles.button} onClick={onSubmit}>
          <p className={styles.buttonContent}>Actualizar perfil</p>
        </button>
      </Form>
    </div>
  )
}

export default Information
