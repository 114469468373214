import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import TextOutlinedInput from "../../../components/doctorComponents/form/outlined-inputs/text";
import EmailOutlinedInput from "../../../components/doctorComponents/form/outlined-inputs/email";
import Autocomplete from "../../../components/doctorComponents/form/outlined-inputs/autocomplete";
import AddSpecialty from "../../../components/doctorComponents/modals/add-specialty";
import Button1 from "../../../components/doctorComponents/buttons/button-1";
import { NotificationManager } from "react-notifications";
import {
  callApi,
  getStateProvinces,
  getCities,
  getSpecialties,
  getDocumentTypes,
  postAddSpecialty,
  getProfileInfo,
  updateProfile,
} from "../../../services/doctorServices/apiService";
import { isRole } from "../../../services/doctorServices/userService";
import { RoleEnum } from "../../../constants/doctorConstants/enums";
import Progress from '../../../components/patientComponents/component/progress'

const Profile = () => {
  const [disable, setDisable] = useState(false);
  const [profileInfo, setProfileInfo] = useState(null);
  const [dlgAddSpecialty, setDlgSpecialty] = useState(false);
  const [stateProvinces, setStateProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [specialties, setSpecialties] = useState([]);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [loader, setLoader] = useState(false);
  const isRepresentative = isRole(RoleEnum.representative);

  const loadData = (obj) => {
    setValue("name", !obj || !obj.name ? "" : obj.name);
    setValue("lastName", !obj || !obj.lastName ? "" : obj.lastName);
    setValue(
      "documentTypeId",
      !obj || !obj.documentTypeId ? null : obj.documentTypeId
    );
    setValue("document", !obj || !obj.document ? "" : obj.document);
    setValue(
      "medicalRecord",
      !obj || !obj.medicalRecord ? "" : obj.medicalRecord
    );
    setValue(
      "stateProvince",
      !obj || !obj.stateProvinceId ? null : obj.stateProvinceId
    );
    setValue("cityId", !obj || !obj.cityId ? null : obj.cityId);
    setValue("mobilePhone", !obj || !obj.mobilePhone ? "" : obj.mobilePhone);
    setValue("numberPhone", !obj || !obj.numberPhone ? "" : obj.numberPhone);
    setValue("specialtyId", !obj || !obj.specialtyId ? null : obj.specialtyId);
    setValue("company", !obj || !obj.company ? "" : obj.company);
    setValue("email", !obj || !obj.email ? "" : obj.email);
  };

  useEffect(() => {
    /* setProfileInfo(getFakeData()); */

    callApi(getProfileInfo, setProfileInfo);
    callApi(getStateProvinces, setStateProvinces);
    callApi(getDocumentTypes, setDocumentTypes);
    callApi(getSpecialties, (data) =>
      setSpecialties([...data, { id: -1, name: "Agregar especialidad" }])
    );
  }, []);

  useEffect(() => {
    loadData(profileInfo);
    setTimeout(() => {
      setDisable(profileInfo !== null);
    }, 500);
  }, [profileInfo]);

  const registerOptions = {
    name: { required: "Debe ingresar al menos un nombre" },
    lastName: { required: "Debe ingresar al menos un apellido" },
    documentType: {
      required: "Por favor seleccione un tipo de identificación",
    },
    document: { required: "Debe ingresar su número de identificación" },

    medicalRecord: { required: "Debe ingresar su registro médico" },
    stateProvince: { required: "Debe seleccionar su departamento" },
    city: { required: "Debe seleccionar su ciudad" },
    specialty: { required: "Debe seleccionar una especialidad" },
    email: {
      required: "Debe ingresar un correo electrónico",
      pattern: {
        value: /\S+@\S+\.\S+/,
        message: "Este correo electrónico no es válido",
      },
    },
  };

  const defaultValues = {
    name: "",
    lastName: "",
    documentTypeId: null,
    document: "",
    medicalRecord: "",
    stateProvince: null,
    cityId: null,
    mobilePhone: "",
    numberPhone: "",
    specialtyId: null,
    company: "",
    email: "",
  };
  const methods = useForm({ defaultValues });
  const { register, setValue, handleSubmit, formState, watch } = methods;
  const { errors } = formState;
  const stateProvinceValue = watch("stateProvince");
  const specialtyValue = watch("specialtyId");

  const onSubmit = handleSubmit((data) => {
    const obj = {
      id: profileInfo.id,
      name: data.name,
      lastName: data.lastName,
      specialtyId: data.specialtyId,
      cityId: data.cityId,
      company: data.company,
      mobilePhone: data.mobilePhone,
      phoneNumber: data.numberPhone, //PhoneNumber
    };
    callApi(
      () => updateProfile(obj),
      () => {
        NotificationManager.success("La operación se realizó correctamente");
      },
      setLoader
    );
  });

  useEffect(() => {
    if (specialtyValue === -1) setDlgSpecialty(true);
  }, [specialtyValue]);

  useEffect(() => {
    if (!stateProvinceValue) {
      setCities([]);
      return;
    }
    callApi(() => getCities(stateProvinceValue), setCities, setLoader);
  }, [stateProvinceValue]);

  const onAddSpecialty = (obj, reset) => {
    callApi(
      () => postAddSpecialty(obj),
      () => {
        callApi(getSpecialties, (data) => {
          const maxId = Math.max(...data.map((x) => x.id));
          setValue("specialtyId", maxId);
          setSpecialties([...data, { id: -1, name: "Agregar especialidad" }]);
          setDlgSpecialty(false);
          reset();
        });
      }
    );
  };

  if (isRepresentative) {
    return (
      <div>
        <div className="container">
          <div className="inner-container">
            <div className="register-wrap">
              <FormProvider {...methods}>
                <TextOutlinedInput
                  name="name"
                  label="Ingrese sus nombres"
                  type={"text"}
                  register={register}
                  registerOptions={registerOptions}
                  errors={errors}
                  required
                  value={watch("name")}
                  disabled={disable}
                />
                <TextOutlinedInput
                  name="lastName"
                  label="Ingrese sus apellidos"
                  type={"text"}
                  register={register}
                  registerOptions={registerOptions}
                  errors={errors}
                  required
                  value={watch("lastName")}
                  disabled={disable}
                />
                <EmailOutlinedInput
                  name="email"
                  label="Ingrese su correo electrónico"
                  register={register}
                  registerOptions={registerOptions}
                  errors={errors}
                  value={watch("email")}
                  disabled={disable}
                />
              </FormProvider>
              <div className="button-wrap">
                <a href="/doctor/change-password">Cambiar Contraseña</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className="container">
        <div className="inner-container">
          <div className="register-wrap">
            <FormProvider {...methods}>
              <TextOutlinedInput
                name="name"
                label="Ingrese sus nombres"
                type={"text"}
                register={register}
                registerOptions={registerOptions}
                errors={errors}
                required
                value={watch("name")}
              />
              <TextOutlinedInput
                name="lastName"
                label="Ingrese sus apellidos"
                type={"text"}
                register={register}
                registerOptions={registerOptions}
                errors={errors}
                required
                value={watch("lastName")}
              />
              <Autocomplete
                name="documentTypeId"
                label="Seleccione su tipo de identificación"
                options={documentTypes}
                register={register}
                registerOptions={registerOptions}
                errors={errors}
                required
                watch={watch}
                setValue={setValue}
                disabled={disable}
              />
              <TextOutlinedInput
                name="document"
                label="Ingrese su número de identificación"
                register={register}
                registerOptions={registerOptions}
                value={watch("document")}
                disabled={disable}
              />
              <TextOutlinedInput
                name="medicalRecord"
                label="Ingrese el número de su registro médico"
                register={register}
                registerOptions={registerOptions}
                value={watch("medicalRecord")}
                disabled={disable}
              />

              <Autocomplete
                name="stateProvince"
                label="Seleccione su departamento"
                options={stateProvinces}
                register={register}
                registerOptions={registerOptions}
                errors={errors}
                required
                watch={watch}
                setValue={setValue}
              />
              <Autocomplete
                name="cityId"
                label="Seleccione su ciudad"
                options={cities}
                register={register}
                registerOptions={registerOptions}
                errors={errors}
                required
                watch={watch}
                setValue={setValue}
              />
              <TextOutlinedInput
                name="mobilePhone"
                label="Ingrese su teléfono celular"
                type={"text"}
                required
                register={register}
                registerOptions={registerOptions}
                errors={errors}
                value={watch("mobilePhone")}
              />
              <TextOutlinedInput
                name="numberPhone"
                label="Ingrese su teléfono fijo"
                type={"text"}
                required
                register={register}
                registerOptions={registerOptions}
                errors={errors}
                value={watch("numberPhone")}
              />

              <Autocomplete
                name="specialtyId"
                label="Seleccione su especialidad"
                options={specialties}
                register={register}
                registerOptions={registerOptions}
                errors={errors}
                required
                watch={watch}
                setValue={setValue}
              />
              <TextOutlinedInput
                name="company"
                label="Ingrese el nombre de su entidad"
                type={"text"}
                required
                register={register}
                registerOptions={registerOptions}
                errors={errors}
                value={watch("company")}
              />

              <EmailOutlinedInput
                name="email"
                label="Ingrese su correo electrónico"
                register={register}
                registerOptions={registerOptions}
                errors={errors}
                value={watch("email")}
                disabled={disable}
              />
            </FormProvider>
            <div className="button-wrap">
              <Button1
                label="Guardar"
                onClick={() => {
                  onSubmit();
                }}
              />
              <a href="/doctor/change-password">Cambiar Contraseña</a>
            </div>
          </div>
        </div>
      </div>
      <Progress loader={loader}/>
      <AddSpecialty
        open={dlgAddSpecialty}
        onClose={() => setDlgSpecialty(false)}
        onAddSpecialty={onAddSpecialty}
      />
    </div>
  );
};

export default Profile;
