import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import Button2 from "../../../components/doctorComponents/buttons/button-2";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextOutlinedInput from "../../../components/doctorComponents/form/outlined-inputs/text";
import DateOutlinedInput from "../../../components/patientComponents/component/form/outlined-inputs/date";
import Autocomplete from "../../../components/patientComponents/component/form/outlined-inputs/autocomplete";
import { NotificationManager } from "react-notifications";
import AddDoctor from "../../../components/patientComponents/component/post-register/addDoctor";
import PersonAddAltIcon from "@material-ui/icons/PersonAdd";
import IconButton from "@material-ui/core/IconButton";

import {
  DocumentTypeList,
  HealthProgramTypeEnum,
  GenreListInt,
  HealthProgramTypeList,
} from "../../../constants/patienteConstants/enums";
import {
  Form,
  Subtitle,
  InnerContainer,
} from "../../../components/patientComponents/styled-components/register/styled";
import {
  callApi,
  getHealthProgramByType,
} from "../../../services/patientServices/apiList";

import {
  callApi as callApiService,
  postAddPatient,
  getPatientInfo,
  GetDoctorList,
  postAddDoctorByInst,
  AssociateUser,
} from "../../../services/doctorServices/apiService";
import dayjs from "dayjs";
import CheckboxOutlinedInput from "../../../components/patientComponents/component/form/outlined-inputs/checkbox";

const isHealthProgramSelected = (healthProgramSelectedItems, type) => {
  if (!healthProgramSelectedItems) return false;
  return healthProgramSelectedItems.find((x) => x === type) !== undefined;
};

const useStyles = makeStyles((theme) => ({
  root: {
    background: "black,",
    "& .MuiDialog-paper": {
      margin: "16px",
    },
    "& .MuiDialogContent-root": {
      padding: "10px 10px 40px 10px",
    },
    "& .MuiTypography-h6": {
      fontFamily: " Source Sans Pro",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "22px",
      lineHeight: "28px",
      padding: "20px 0 0 10px",
      color: "#343A40",
    },
  },
  modalLabel: {
    width: "100%",
    maxWidth: "290px",
    margin: "0 auto 0 auto",
  },
}));

const DocumentList = [
  "",
  "Cédula de ciudadanía",
  "Cédula de extranjería",
  "Tarjeta de identidad",
  "Otro",
];

const AddPatient = ({
  open,
  onClose,
  onAddSpecialty,
  setCurrentPatientId,
  currentPatientId,
}) => {
  const classes = useStyles();

  const defaultValues = { legal: false };
  const methods = useForm({ defaultValues });
  const { register, handleSubmit, formState, watch, setValue, reset } = methods;
  const { errors } = formState;
  const birthdayValue = watch("birthday");
  const [doctors, setDoctors] = useState([]);
  const [epsList, setEpsList] = useState([]);
  const healthProgramValue = watch("healthProgram");
  const legalValue = watch("legal");
  const hasNoMailValue = watch("hasNoMail");
  const [supplementalPlanList, setSupplementalPlanList] = useState([]);
  const [prepaidMedicinePlanList, setPrepaidMedicinePlanList] = useState([]);
  const [healthPolicyList, setHealthPolicyList] = useState([]);

  const [btnDisabled, setBtnDisabled] = useState(false);
  const [patientInfo, setPatientInfo] = useState(null);

  const [dlgAddDoctor, setDlgAddDoctor] = useState(false);

  const [dlgValidationPatient, setDlgValidationPatient] = useState(false);
  const [existingPatient, setExistingPatient] = useState({});

  const [hasNoMailChecked, setHasNoMailChecked] = useState(false);
  const [legalCheck, setLegalCheck] = useState(false);
  

  const loadHealthProgramType = (selectedTypes) => {
    const callLoad = (type, setData) => {
      callApi(() => getHealthProgramByType(type), setData);
    };

    if (isHealthProgramSelected(selectedTypes, HealthProgramTypeEnum.eps)) {
      if (epsList.length === 0) callLoad(HealthProgramTypeEnum.eps, setEpsList);
    } else {
      setValue("eps", "");
    }

    if (
      isHealthProgramSelected(
        selectedTypes,
        HealthProgramTypeEnum.supplementalPlan
      )
    ) {
      if (supplementalPlanList.length === 0)
        callLoad(
          HealthProgramTypeEnum.supplementalPlan,
          setSupplementalPlanList
        );
    } else {
      setValue("supplementalPlan", "");
    }

    if (
      isHealthProgramSelected(
        selectedTypes,
        HealthProgramTypeEnum.prepaidMedicine
      )
    ) {
      if (prepaidMedicinePlanList.length === 0)
        callLoad(
          HealthProgramTypeEnum.prepaidMedicine,
          setPrepaidMedicinePlanList
        );
    } else {
      setValue("prepaidMedicine", "");
    }

    if (
      isHealthProgramSelected(selectedTypes, HealthProgramTypeEnum.healthPolicy)
    ) {
      if (healthPolicyList.length === 0)
        callLoad(HealthProgramTypeEnum.healthPolicy, setHealthPolicyList);
    } else {
      setValue("healthPolicy", "");
    }
  };

  const registerOptions = {
    firstnames: { required: "Debe ingresar al menos un nombre" },
    lastnames: { required: "Debe ingresar al menos un apellido" },
    selectId: {
      required: "Por favor seleccione un tipo de identificación",
    },
    numberId: { required: "Debe ingresar el número de identificación" },
    genre: { required: "Por favor selecione el género" },
    email: {
      required: !hasNoMailChecked && "Debe ingresar un correo electrónico",
      pattern: {
        value: /\S+@\S+\.\S+/,
        message: "Este correo electrónico no es válido",
      },
    },
    typeDocument: { required: "Debe seleccionar un tipo de documento" },
    healthProgram: { required: "Debe seleccionar por lo menos un programa" },
    doctor: { required: "Debe seleccionar un médico" },
    legal: {
      validate: (value) =>
        value === true ||
        "Debe aceptar los Términos y Condiciones para completar el registro",
    },
  };

  useEffect(() => {
    if (healthProgramValue) {
      loadHealthProgramType(healthProgramValue);
      return;
    }
  }, [healthProgramValue]);

  useEffect(() => {
    callApiService(GetDoctorList, (data) => setDoctors(data));
  }, []);

  useEffect(() => {
    if (!currentPatientId) {
      setPatientInfo(null);
      return;
    }
    getPatientInfoFn(currentPatientId);
  }, [currentPatientId]);

  const getPatientInfoFn = (currentPatientId) => {
    callApiService(
      () => getPatientInfo(currentPatientId),
      (data) => {
        loadPatientInfo(data);
        setPatientInfo(data);
      }
    );
  };

  const getHealthProgram = (patient) => {
    let healthProgram = [];
    if (patient.epsId) healthProgram.push(HealthProgramTypeEnum.eps);
    if (patient.prepaidMedicineId)
      healthProgram.push(HealthProgramTypeEnum.prepaidMedicine);
    if (patient.supplementalPlanId)
      healthProgram.push(HealthProgramTypeEnum.supplementalPlan);
    if (patient.healthPolicyId)
      healthProgram.push(HealthProgramTypeEnum.healthPolicy);

    return healthProgram;
  };
  const loadPatientInfo = (obj) => {
    setValue("healthProgram", getHealthProgram(obj));
    setValue("firstnames", obj.name);
    setValue("lastnames", obj.lastname);
    setValue("typeDocument", obj.documentType);
    setValue("numberId", obj.document);
    setHasNoMailChecked(obj.hasNoMail?true:false);
    setValue("email", obj.hasNoMail?"":obj.email);
    setValue("lastnames", obj.lastname);
    setValue("birthday", obj.birthday== "0001-01-01T00:00:00"?null: obj.birthday);
    setValue("genre", obj.genre);
    setValue("doctor", obj.doctorId);
    setValue("prepaidMedicine", obj.prepaidMedicineId);
    setValue("eps", obj.epsId);
    setValue("healthPolicy", obj.healthPolicyId);
    setValue("supplementalPlan", obj.supplementalPlanId);
  };
  const onCloseHandle = () => {
    reset({
      firstnames: "",
      lastnames: "",
      numberId: "",
      email: "",
      birthday: "",
      genre: "",
      healthProgram: "",
      typeDocument: "",
      doctor: "",
      healthProgram: "",
    });
    setValue("birthday", null);
    setHasNoMailChecked(false);
    setLegalCheck(false);
    setCurrentPatientId(null);
    onClose();
  };

  const doctorValue = watch("doctor");

  const onAdddDoctor = (doctor, reset) => {
    callApi(
      () => postAddDoctorByInst(doctor),
      () => {
        callApi(GetDoctorList, (data) => {
          const maxId = Math.max(...data.map((x) => x.id));
          setValue("doctor", maxId);
          setDoctors(data);
          setDlgAddDoctor(false);
          reset();
        });
      }
    );
  };

  const onCloseDlgValidation = () => {
    reset({
      firstnames: "",
      lastnames: "",
      numberId: "",
      email: "",
      birthday: "",
      genre: "",
      healthProgram: "",
      typeDocument: "",
      doctor: "",
      healthProgram: "",
    });
    setValue("birthday", null);
    setExistingPatient({});
    setCurrentPatientId(null);
    setDlgValidationPatient(false);
    onClose();
  };

  const handleCheckboxChange = (event) => {
    setValue("email", "");
    setHasNoMailChecked(event.target.checked);
  };
  const handleCheckboxChangeLegal = (event) => {
    setValue("legal", event.target.checked)
    setLegalCheck(event.target.checked);
  };

  const onSubmit = handleSubmit((data) => {
    setBtnDisabled(true);
    const patient = {
      id: currentPatientId,
      name: data.firstnames,
      lastName: data.lastnames,
      documentType: data.typeDocument,
      document: data.numberId,
      genre: Number(data.genre),
      birthday: !data.birthday
        ? null
        : dayjs(data.birthday).format("YYYY-MM-DDTHH:mm:ss"),
      hasNoMail:hasNoMailChecked,
      email: hasNoMailChecked ==true?"":data.email,
      epsId: !data.eps ? null : data.eps,
      supplementalPlanId: !data.supplementalPlan ? null : data.supplementalPlan,
      prepaidMedicineId: !data.prepaidMedicine ? null : data.prepaidMedicine,
      healthPolicyId: !data.healthPolicy ? null : data.healthPolicy,
      doctorId: data.doctor,
    };
    callApiService(
      () => postAddPatient(patient),
      (data) => {
        if (data !== null && data !== "") {
          setExistingPatient(data);
          setDlgValidationPatient(true);
          return;
        }
        if (patient.id != null) {
          NotificationManager.success("El usuario se ha editado correctamente");
          setBtnDisabled(false);
          onCloseHandle();
          window.location.reload();
          return;
        }
        NotificationManager.success("El usuario se ha creado correctamente");
        onCloseHandle();
        setBtnDisabled(false);
        window.location.reload();
        return;
      }
    );
    setBtnDisabled(false);
  });

  const AssociateUserCommand = (patient) => {
    callApiService(
      () => AssociateUser(patient),
      () => {
        onCloseDlgValidation();
        window.location.reload();
        return;
      }
    );
  };

  return (
    <>
      <Dialog
        className={classes.root}
        open={open}
        keepMounted
        onClose={onCloseHandle}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {currentPatientId == null
            ? "Agregar nuevo paciente"
            : "Editar paciente"}
        </DialogTitle>
        <DialogContent>
          <div>
            <Form onSubmit={onSubmit}>
              <InnerContainer>
                <Subtitle>Información de usuario</Subtitle>
                <TextOutlinedInput
                  name="firstnames"
                  label="Nombres"
                  type={"text"}
                  register={register}
                  registerOptions={registerOptions}
                  errors={errors}
                  required
                  value={watch("firstnames") ?? ""}
                />
                <TextOutlinedInput
                  style={{ marginTop: "-5px" }}
                  name="lastnames"
                  label="Apellidos"
                  type={"text"}
                  required
                  register={register}
                  registerOptions={registerOptions}
                  errors={errors}
                  value={watch("lastnames") ?? ""}
                />
                <Autocomplete
                  style={{ marginTop: "-13px" }}
                  register={register}
                  registerOptions={registerOptions}
                  name="typeDocument"
                  label="Tipo de Identificación"
                  placeholder="Tipo de Identificación"
                  watch={watch}
                  options={DocumentTypeList()}
                  required
                  setValue={setValue}
                  errors={errors}
                />
                <TextOutlinedInput
                  style={{ marginTop: "5px" }}
                  name="numberId"
                  label="Número de identificación"
                  type={"number"}
                  required
                  register={register}
                  registerOptions={registerOptions}
                  errors={errors}
                  value={watch("numberId") ?? ""}
                />
                <Autocomplete
                  style={{ marginTop: "-10px" }}
                  register={register}
                  registerOptions={registerOptions}
                  name="genre"
                  label="Por favor seleccione su género"
                  placeholder="Por favor seleccione su género"
                  watch={watch}
                  options={GenreListInt()}
                  required
                  setValue={setValue}
                  errors={errors}
                />
                <DateOutlinedInput
                  style={{ marginTop: "15px" }}
                  name="birthday"
                  label="Fecha de Nacimiento"
                  register={register}
                  defaultValue={null}
                  registerOptions={registerOptions}
                  errors={errors}
                  disableFuture={true}
                  onChange={(date) => setValue("birthday", date)}
                  value={birthdayValue ?? null}
                />
                <TextOutlinedInput
                  style={{ marginTop: "10px" }}
                  name="email"
                  label="Correo electrónico"
                  required = {!hasNoMailChecked}
                  register={register}
                  registerOptions={registerOptions}
                  errors={errors}
                  value={watch("email") ?? ""}
                  disabled = {hasNoMailChecked}
                />
                <div style={{ marginTop: "-20px" }}>
                <CheckboxOutlinedInput
                  id="hasNoMail"
                  name="hasNoMail"
                  register={register}
                  checked={hasNoMailChecked}
                  registerOptions={registerOptions}
                  errors={errors}
                  value={hasNoMailValue ?? false}
                  onChange={handleCheckboxChange}
                >
                  No tiene correo electronico
                </CheckboxOutlinedInput>
                </div>
                <Autocomplete
                  style={{ marginTop: "-10px" }}
                  register={register}
                  registerOptions={registerOptions}
                  name="healthProgram"
                  label="Seleccione su programa en salud"
                  placeholder="Seleccione su programa en salud"
                  multiple
                  options={HealthProgramTypeList()}
                  required
                  watch={watch}
                  setValue={setValue}
                  errors={errors}
                />
                {isHealthProgramSelected(
                  healthProgramValue,
                  HealthProgramTypeEnum.eps
                ) && (
                  <Autocomplete
                    register={register}
                    registerOptions={registerOptions}
                    name="eps"
                    label="EPS"
                    placeholder="Seleccione su EPS"
                    options={epsList}
                    required
                    watch={watch}
                    setValue={setValue}
                    errors={errors}
                  />
                )}
                {isHealthProgramSelected(
                  healthProgramValue,
                  HealthProgramTypeEnum.supplementalPlan
                ) && (
                  <Autocomplete
                    register={register}
                    registerOptions={registerOptions}
                    name="supplementalPlan"
                    label="Plan complementario"
                    placeholder="Seleccione su plan complementario"
                    options={supplementalPlanList}
                    required
                    watch={watch}
                    setValue={setValue}
                    errors={errors}
                  />
                )}
                {isHealthProgramSelected(
                  healthProgramValue,
                  HealthProgramTypeEnum.prepaidMedicine
                ) && (
                  <Autocomplete
                    register={register}
                    registerOptions={registerOptions}
                    name="prepaidMedicine"
                    label="Medicina prepagada"
                    placeholder="Seleccione una opción"
                    options={prepaidMedicinePlanList}
                    required
                    watch={watch}
                    setValue={setValue}
                    errors={errors}
                  />
                )}
                {isHealthProgramSelected(
                  healthProgramValue,
                  HealthProgramTypeEnum.healthPolicy
                ) && (
                  <Autocomplete
                    register={register}
                    registerOptions={registerOptions}
                    name="healthPolicy"
                    label="Póliza en salud"
                    placeholder="Seleccione una opción"
                    options={healthPolicyList}
                    required
                    watch={watch}
                    setValue={setValue}
                    errors={errors}
                  />
                )}

                <div
                  className="row"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Autocomplete
                    register={register}
                    registerOptions={registerOptions}
                    name="doctor"
                    label="Seleccione el médico que le formuló"
                    placeholder="Seleccione el médico que le formuló *"
                    options={doctors}
                    required
                    watch={watch}
                    setValue={setValue}
                    errors={errors}
                  />
                  <IconButton
                    aria-label="search"
                    onClick={() => {
                      setDlgAddDoctor(true);
                    }}
                  >
                    <PersonAddAltIcon />
                  </IconButton>
                </div>
              </InnerContainer>
              <CheckboxOutlinedInput
                id="legal"
                name="legal"
                register={register}
                registerOptions={registerOptions}
                checked={legalCheck}
                errors={errors}
                value={legalValue ?? false}
                onChange={handleCheckboxChangeLegal}
              >
                He leído y acepto la{" "}
                <a
                  href="https://app.ialuril.co/patient/privacyPolicy"
                  target="_blank"
                >
                  Política de Privacidad
                </a>{" "}
                y los{" "}
                <a
                  href="https://app.ialuril.co/patient/termsAndConditions"
                  target="_blank"
                >
                  Términos y Condiciones
                </a>
              </CheckboxOutlinedInput>
            </Form>
            <div className={"div-horizontal"}>
              <Button2 label="Cerrar" onClick={onCloseHandle} />
              <Button2
                disabled={btnDisabled}
                label="Guardar"
                onClick={onSubmit}
              />
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <AddDoctor
        open={dlgAddDoctor}
        onClose={() => {
          setDlgAddDoctor(false);
          setValue("doctor", null);
        }}
        onAddDoctor={onAdddDoctor}
      />
      <Dialog
        className={classes.root}
        open={dlgValidationPatient}
        onClose={onCloseDlgValidation}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogContent>
          <div>
            <div className="alert-pill text-container-margin">
              <p>{existingPatient.errorMessage}</p>
            </div>
            <div className={"text-container-padding-margin"}>
              <div>
                <p>{existingPatient.asociateUserMessage}</p>
              </div>
            </div>
            <div className={"text-container-padding-margin"}>
              <p>
                <b>Paciente:</b>
                {" " + existingPatient.name + " " + existingPatient.lastname}
              </p>
              <p>
                <b>{DocumentList[existingPatient.documentType]}:</b>
                {" " + existingPatient.document}
              </p>
              <p>
                <b>Correo electrónico:</b>
                {" " + existingPatient.email}
              </p>
            </div>
            <div className={"div-horizontal"}>
              <Button2 label="Cerrar" onClick={onCloseDlgValidation} />
              {existingPatient.allowAssociateUser && (
                <Button2
                  disabled={btnDisabled}
                  label="Agregar"
                  onClick={() => AssociateUserCommand(existingPatient)}
                />
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddPatient;
