import React from 'react'
import { Link } from "react-router-dom";
import styles from '../../../../styles/patientStyles/Dashboard.module.css'

const RelevantInformation = () => {
  return (
    <div className={styles.innerContainerRelevantInformation}>
      <Link to="/education/">
        <p style={{ cursor: 'pointer' }} className={styles.title1}>
          INFORMACIÓN RELEVANTE
        </p>
      </Link>
      <div className={styles.infoContainer}>
        <Link to="/patient/education/what-is-an-intravesical-instillation">
          <div className={`${styles.relevantInformationBox}`}>
            <img
              className={`${styles.recommendationImg}`}
              src="/patient/educacionPreview1.png"
              alt="Sun"
            />
            <p className={`${styles.relevantInformationText}`}>
              ¿Qué es una instilación intravesical?
            </p>
          </div>
        </Link>
        <Link to="/patient/education/who-covers-this-procedure">
          <div className={`${styles.relevantInformationBox}`}>
            <img
              className={`${styles.recommendationImg}`}
              src="/patient/educacionPreview2.png"
              alt="Sun"
            />
            <p className={`${styles.relevantInformationText}`}>
              Este procedimiento ¿quién lo cubre?
            </p>
          </div>
        </Link>
        <Link to="/patient/education/documentation-for-the-procedure">
          <div className={`${styles.relevantInformationBox}`}>
            <img
              className={`${styles.recommendationImg}`}
              src="/patient/educacionPreview3.png"
              alt="Sun"
            />
            <p className={`${styles.relevantInformationText}`}>
              Documentos que debe tener en cuenta
            </p>
          </div>
        </Link>
      </div>
      <p className={styles.invima}>Registro Invima 2014DM-0012306</p>
    </div>
  )
}

export default RelevantInformation
