import React from "react";
import Footer from "../../components/doctorComponents/footer";
import GreetingsHeader from "../../components/doctorComponents/header/greetings-header";
import HeaderWithMenu from "../../components/doctorComponents/headerWithMenu";
import PreRegisterBanner from "../../components/doctorComponents/banners/pre-register-banner";
import { getUserInfo } from "../../services/doctorServices/authService";

import { isRole } from "../../services/doctorServices/userService";
import { RoleEnum } from "../../constants/doctorConstants/enums";

const GreetingsHeaderLayout = (props) => {
  const userlogin = getUserInfo();
  const isRepresentative = isRole(RoleEnum.representative);
  const isInstitution = isRole(RoleEnum.institution);

  return (
    <div>
      {userlogin !== null && (
        <HeaderWithMenu img="/doctor/menu-mobile-pattern.png" />
      )}
      {userlogin === null && <PreRegisterBanner />}
      <div className="main">
        {
        isRepresentative ? (
          <GreetingsHeader
            img="/doctor/bg-header1-xxxhdpi.png"
            name={userlogin.lastname + " " + userlogin.name}
          />
        ): isInstitution ? (
          <GreetingsHeader
            img="/doctor/bg-header1-xxxhdpi.png"
            name={userlogin.name}
          />
        ) : (
          <GreetingsHeader
          img="/doctor/bg-header1-xxxhdpi.png"
          name={"Dr. " + userlogin.lastname}
          />)
        }
        {props.children}
        <Footer />
      </div>
    </div>
  );
};

export default GreetingsHeaderLayout;
