import { getUserInfo } from "./authService";

export const isRole = (role) => {
  const userInfo = localStorage.getItem("userInfo");
  if(!userInfo) return false;
  return JSON.parse(userInfo).roles.indexOf(role) !== -1;
};

export const getRoleUser = () => {
  var userInfo = getUserInfo();
  if (!userInfo) return { roles: "" };

  var role = userInfo.roles[0];
  role = role ? role.toLowerCase() : "";

  return {
    role: role,
  };
};
