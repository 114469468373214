import React from "react";
import BreadCrumbComp from "../../breadcrumb/index";
const BreadcrumbsHeader = (props) => {
  return (
    <div>
      <div
        style={{
          background: `url("${props.img}") center no-repeat #343A40`,
          backgroundSize: "cover",
        }}
      >
        <div
          style={{
            maxWidth: "576px",
            margin: "0 auto",
            height: "200px",
            display: "flex",
            justifyContent: "space-between"
          }}
          className="header-bread"
        >
          <BreadCrumbComp />
          <p className="header-subtitle">{props.subtitle}</p>
          <p className="header-register">Registro Invima 2014DM-0012306</p>
        </div>
        </div>
        <p className="header-2-bread-title">{props.title}</p>
    </div>
  );
};

export default BreadcrumbsHeader;
