import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  HeaderLogo,
  Container,
  Form,
  LoginButton,
  ForgotPassButton,
  RegisterButton,
  Logo,
  Title,
  MarginSeparator,
} from "../../../components/patientComponents/styled-components/login/styled";
import PasswordOutlinedInput from "../../../components/patientComponents/component/form/outlined-inputs/password";
import EmailOutlinedInput from "../../../components/patientComponents/component/form/outlined-inputs/email";
import Progress from "../../../components/patientComponents/component/progress";
import { callApi, getToken } from "../../../services/patientServices/apiList";
import {
  UserStateEnum,
  RoleEnum,
} from "../../../constants/patienteConstants/enums";
import {
  getUserInfo,
  signOut,
  saveUserInfo,
} from "../../../services/patientServices/authService";
import Contact from "../../../components/patientComponents/component/Modals/contact";
import { getTokenMsg } from "../../../firebase/methods"


const PatientLogin = () => {
  let history = useHistory();
  const [dlgContac, setDlgContact] = useState(false);
  const [loader, setLoader] = useState(false);
  const [tokenNotification, setTokenNotification] = useState("");


  useEffect(() => {
    if (getUserInfo() !== null) signOut();
  }, []);

  const handleClose = () => {
    setDlgContact(false);
  };

  const handleOpen = () => {
    setDlgContact(true);
  };

  const defaultValues = {
    // email: "",
    // password: "",
  };

  const methods = useForm({ defaultValues });
  const { register, handleSubmit, formState } = methods;
  const { errors } = formState;

  const onSubmit = handleSubmit((data) => {
    callApi(
      () =>
        getToken({
          username: data.email,
          password: data.password,
          tokenNotification: tokenNotification,
        }),
      (userInfo) => {
        if(userInfo.role == 1){
          userInfo.requestPermission = false;
        }
        saveUserInfo(userInfo);
        const page =
          userInfo.role === RoleEnum.admin
            ? "/patient/relogin"
            : userInfo.state === UserStateEnum.active
            ? "/patient/dashboard"
            : "/patient/dialog-profile";
        history.push(page);
      },
      setLoader
    );
  });

  const registerOptions = {
    email: {
      required: "Ingrese su correo electrónico",
      pattern: {
        value: /\S+@\S+\.\S+/,
        message: "Este formato de correo electrónico no es válido",
      },
    },
    password: { required: "Ingrese su contraseña" },
  };

  useEffect(() => {
    generateTokenNotification();
  }, []);

  const generateTokenNotification = async () => {
    const token = await getTokenMsg();
    setTokenNotification(token);
  };
  
  return (
    <>
      <Container>
        <HeaderLogo>
          <Logo src="/patient/logo-color.svg" alt="ialuriApp Logo" />
          <Title>Su aliado en el tratamiento con ialuril ® Prefill</Title>
        </HeaderLogo>

        <Form onSubmit={onSubmit}>
          <EmailOutlinedInput
            name="email"
            label="Correo electrónico"
            required={true}
            register={register}
            registerOptions={registerOptions}
            errors={errors}
          />

          <MarginSeparator />

          <PasswordOutlinedInput
            name="password"
            label="Contraseña"
            required={true}
            register={register}
            registerOptions={registerOptions}
            errors={errors}
          />

          <LoginButton>Iniciar sesi&oacute;n</LoginButton>
        </Form>

        <ForgotPassButton>
          <Link to="/patient/forgot-password">
            Olvid&eacute; mi contrase&ntilde;a
          </Link>
        </ForgotPassButton>

        <RegisterButton>
          ¿No tiene una cuenta?
          <Link to="/patient/register">Reg&iacute;strese</Link>
        </RegisterButton>

        <RegisterButton>
          ¿Necesita ayuda?{" "}
          <u
            style={{
              cursor: "pointer",
              margin: "15px 0 0 0",
              fontSize: "13px",
              color: "#b16db3",
            }}
            onClick={handleOpen}
          >
            Contáctenos
          </u>
        </RegisterButton>
      </Container>

      <Progress loader={loader} />

      <Contact open={dlgContac} onClose={handleClose} />
    </>
  );
};

export default PatientLogin;
