import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link as RouterLink, useLocation } from "react-router-dom";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";

const breadcrumbNameMap = {
  "/module-1": "MÓDULO 1",
  "/module-1/bases-of-chronic-cystitis": "ARTÍCULO 1",
  "/module-1/bacterial-cystitis": "ARTÍCULO 2",
  "/module-1/interstitial-cystitis-painful-bladder-syndrome": "ARTÍCULO 3",
  "/module-1/hemorrhagic-cystitis": "ARTÍCULO 4",

  "/module-2": "MÓDULO 2",
  "/module-2/chronic-bacterial-cystitis": "ARTÍCULO 1",
  "/module-2/interstitial-cystitis": "ARTÍCULO 2",
  "/module-2/hemorrhagic-cystitis-2": "ARTÍCULO 3",


  "/module-3/chronic-bacterial-cystitis-3": "ARTÍCULO 1",
  "/module-3/interstitial-cystitis-3": "ARTÍCULO 2",
  "/module-3/hemorrhagic-cystitis-3": "ARTÍCULO 3",

  "/module-4": "MÓDULO 4",
  "/module-4/ialuril-the-ideal-complement": "ARTÍCULO 1",
  "/module-4/about-ialuril-Prefill": "ARTÍCULO 2",

  "/module-5": "MÓDULO 5",

  "/module-6": "MÓDULO 6",

  "/patient-list": "LISTADO DE PACIENTES",
  "/patient-list/patient-details": "FICHA DEL PACIENTE",
  
  "/doctor-list": "LISTADO DE MÉDICOS",
  "/doctor-list/doctor-details": "FICHA DEL MÉDICO",

  "/eps-list": "LISTADO DE EPS",
  "/eps-list/eps-details":"FICHA DE LA EPS",
  "/eps-list/eps-details/patient-list":"LISTADO DE PACIENTES",

  "/report": "REPORTES",


  "/change-password": "CAMBIAR CONTRASEÑA",
  "/profile": "PERFIL",
  "/privacy-policy": "POLÍTICA DE PRIVACIDAD",
  "/terms-and-conditions": "TÉRMINOS Y CONDICIONES",

  "/videos": "VIDEOS",
  "/videos-ialuadapter": "VIDEOS IALUADAPTER",


  "/patient-education": "INFORMACIÓN RELEVANTE",
  "/patient-education/what-is-an-intravesical-instillation":
    "¿QUÉ ES UNA INSTILACIÓN INTRAVESICAL?",
  "/patient-education/who-covers-this-procedure":
    "ESTE PROCEDIMIENTO ¿QUIÉN LO CUBRE?",
  "/patient-education/documentation-for-the-procedure":
    "DOCUMENTOS QUE DEBE TENER EN CUENTA",

  "/patient-recommendations": "RECOMENDACIONES GENERALES",
  "/patient-recommendations/before-intravesical-instillation":
    "ANTES DE LA INSTILACIÓN",
  "/patient-recommendations/the-day-of-intravesical-instillation":
    "EL DÍA DE LA INSTILACIÓN",
  "/patient-recommendations/after-intravesical-instillation":
    "DESPUÉS DE LA INSTILACIÓN",
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: 360,
  },
  lists: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(1),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  text: {
    fontFamily: "Source Sans Pro",
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "14px",
    lineHeight: "18px",

    color: "#FFFFFF",
  },
  icon: {
    color: "#FFFFFF",
  },
}));

const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

const BreadCrumbComp = () => {
  const classes = useStyles();
  const location = useLocation();

  const doctorPath = location.pathname.split("/").filter((x) => x);

  const pathnames = (doctorPath.splice(1, doctorPath.length))

  return (
    <Breadcrumbs
      separator={<NavigateNextIcon className={classes.icon} />}
      aria-label="breadcrumb"
    >
      <LinkRouter color="inherit" to="/doctor/dashboard">
        <HomeOutlinedIcon className={classes.icon} />
      </LinkRouter>
      {pathnames.map((value, index) => {
        const last = index === pathnames.length - 1;
        const to = `/${pathnames.slice(0, index + 1).join("/")}`;

        return last ? (
          <p className={classes.text} key={to}>
            {breadcrumbNameMap[to]}
          </p>
        ) : (
          <LinkRouter color="inherit" to={`/doctor${to}`} key={to}>
            <p className={classes.text}>{breadcrumbNameMap[to]}</p>
          </LinkRouter>
        );
      })}
    </Breadcrumbs>
  );
};

export default BreadCrumbComp;
