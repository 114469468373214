import React from "react";

function showGreeting() {
  const fecha = new Date();
  const hour = fecha.getHours();

  let text = "Buenos Días,";

  if (hour >= 0 && hour < 12) {
    return text;
  }

  if (hour >= 12 && hour < 18) {
    text = "Buenas Tardes,";
    return text;
  }

  if (hour >= 18 && hour < 24) {
    text = "Buenas Noches,";
    return text;
  }
}

function showPicture() {
  const fecha = new Date();
  const hour = fecha.getHours();

  let img = "/doctor/Sun.svg";

  if (hour >= 0 && hour < 12) {
    return img;
  }

  if (hour >= 12 && hour < 18) {
    img = "/doctor/Sun2.svg";
    return img;
  }

  if (hour >= 18 && hour < 24) {
    img = "/doctor/Moon.svg";
    return img;
  }
}

const GreetingsHeader = (props) => {
  return (
    <div
      style={{
        background: `url("${props.img}") center no-repeat #343A40`,
        backgroundSize: "cover",
      }}
    >
      <div
        style={{
          maxWidth: "576px",
          margin: "0 auto",
          //height: "200px",
          display: "flex",
          justifyContent: "flex-end",
        }}
        className="header-2"
      >
        <div className="greetings">
          <img src={showPicture()} alt="Sun" />
          <p className="header-2-greetings">{showGreeting()}</p>
        </div>
        <p className="header-2-name">{props.name}</p>
        <p className="header-register">Registro Invima 2014DM-0012306</p>
      </div>
    </div>
  );
};

export default GreetingsHeader;
