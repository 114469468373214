import React from 'react'
import { Link } from 'react-router-dom'
import styles from '../../../../styles/patientStyles/Dashboard.module.css'

const GeneralRecommendations = () => {
  return (
    <div className={styles.recommendationContainer}>
      <div className={styles.innerContainer3}>
        <Link to="/recommendations/">
          <p style={{ cursor: 'pointer' }} className={styles.title2}>
            RECOMENDACIONES GENERALES
          </p>
        </Link>
        <Link to="/patient/recommendations/before-intravesical-instillation">
          <div className={`${styles.recommendationBox}`}>
            <img
              className={`${styles.recommendationImg}`}
              src="/patient/recommendationsPreview-1.png"
              alt="Sun"
            />
            <p className={`${styles.imageText}`}>
              Antes de la instilación intravesical
            </p>
          </div>
        </Link>

        <Link to="/patient/recommendations/the-day-of-intravesical-instillation">
          <div className={`${styles.recommendationBox}`}>
            <img
              className={`${styles.recommendationImg}`}
              src="/patient/recommendationsPreview-2.png"
              alt="Sun"
            />
            <p className={`${styles.imageText}`}>
              El día de la instilación intravesical
            </p>
          </div>
        </Link>
        <Link to="/patient/recommendations/after-intravesical-instillation">
          <div className={`${styles.recommendationBox}`}>
            <img
              className={`${styles.recommendationImg}`}
              src="/patient/recommendationsPreview-3.png"
              alt="Sun"
            />
            <p className={`${styles.imageText}`}>
              Después de la instilación intravesical{' '}
            </p>
          </div>
        </Link>
        <p className={styles.invima}>Registro Invima 2014DM-0012306</p>
      </div>
    </div>
  )
}

export default GeneralRecommendations
