import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { NotificationManager } from "react-notifications";

import { useHistory } from "react-router-dom";
import styles from "../../../styles/patientStyles/Dashboard.module.css";
import HeaderWithMenu from "../../../components/patientComponents/component/headerWithMenu/index";
import InfoCard from "../../../components/patientComponents/component/infoCard";
import Footer from "../../../components/patientComponents/component/footer";
import CircleProgress from "../../../components/patientComponents/component/dashboard/circleProgress";
import GeneralRecommendations from "../../../components/patientComponents/component/dashboard/generalRecommendations";
import RelevantInformation from "../../../components/patientComponents/component/dashboard/relevantInformation";
import Progress from "../../../components/patientComponents/component/progress/index";
import { getUserInfo, saveUserInfo } from "../../../services/patientServices/authService";
import { UserStateEnum } from "../../../constants/patienteConstants/enums";
import DashboardState from "./dashboardState";
import {
  callApi,
  getDashboard,
  saveTokenNotification,
} from "../../../services/patientServices/apiList";
import { RoleEnum } from "../../../constants/patienteConstants/enums";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import Button2 from "../../../components/doctorComponents/buttons/button-2";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "black,",
    "& .MuiDialog-paper": {
      margin: "16px",
    },
    "& .MuiDialogContent-root": {
      padding: "10px 10px 40px 10px",
    },
    "& .MuiTypography-h6": {
      fontFamily: " Source Sans Pro",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "22px",
      lineHeight: "28px",
      padding: "20px 0 0 10px",
      color: "#343A40",
    },
  },
  modalLabel: {
    width: "100%",
    maxWidth: "400px",
    margin: "0 auto 0 auto",
  },
}));

function showGreeting() {
  const fecha = new Date();
  const hour = fecha.getHours();

  let text = "Buenos Días,";

  if (hour >= 0 && hour < 12) {
    return text;
  }

  if (hour >= 12 && hour < 18) {
    text = "Buenas Tardes,";
    return text;
  }

  if (hour >= 18 && hour < 24) {
    text = "Buenas Noches,";
    return text;
  }
}

function showPicture() {
  const fecha = new Date();
  const hour = fecha.getHours();

  let img = "/patient/Sun.svg";

  if (hour >= 0 && hour < 12) {
    return img;
  }

  if (hour >= 12 && hour < 18) {
    img = "/patient/Sun2.svg";
    return img;
  }

  if (hour >= 18 && hour < 24) {
    img = "/patient/Moon.svg";
    return img;
  }
}

const PatientDashboard = () => {
  const classes = useStyles();
  const history = useHistory();
  const [dashboardInfo, setDashboardInfo] = useState({});
  const [loader, setLoader] = useState(false);
  const userInfo = getUserInfo();

  const loadDashboard = () => {
    callApi(
      getDashboard,
      (data) => {
        setDashboardInfo(data);
        if (data.userState !== UserStateEnum.active) {
          history.push("/dialog-profile");
          return;
        }
      },
      setLoader
    );
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const pushNotification = () => {
    if((userInfo.requestPermission || userInfo.requestPermission === null) && userInfo.tokenNotification != "" &&
    !userInfo.isRelogin ){
      if(!userInfo.requestPushTokenNotification){
        handleOpen();
      }
    }
  };
  
  const setNotifications =  (confirm) => {
    userInfo.requestPermission = confirm;
    saveUserInfo(userInfo);
    callApi(
      () =>
      saveTokenNotification({
        tokenNotification: userInfo.tokenNotification,
        permissionNotification: confirm
      })
      );
      handleClose();
  }

  useEffect(() => {
    loadDashboard();
    pushNotification();
  }, []);

 
  const onClick = (data) => {
    if (data.completedSurveys === 0) {
      NotificationManager.warning("Actualmente no tiene encuestas finalizadas");
      return;
    }
    const item = {
      completedSurveys:data.CompletedSurveys,
      currentCycle: data.currentCycle? data.currentCycle: "",
      fullName: data.patient,
      patientId: data.patientId
     }
     
    history.push({
      pathname: "/patient/patient-details",
      state: { detail: item },
    });
  };

  return (
    <>
      <HeaderWithMenu />
      <div className={styles.tableContainer}>
        <div className={styles.innerContainer}>
          <div>
            <p className={styles.greeting}>{showGreeting()}</p>
            <div className={styles.nameContainer}>
              <p className={styles.name}>{dashboardInfo.patient ?? ""}</p>
              <img className={`${styles.img}`} src={showPicture()} alt="Sun" />
            </div>
          </div>
          <div className={styles.infoBox}>
            <DashboardState
              dashboardInfo={dashboardInfo}
              onRefreshDashboard={loadDashboard}
            />
            {/*  <img className={`${styles.line}`} src="/Line1.svg" alt="bar" /> */}
            <div className={styles.grid}>
              <div>
                <InfoCard
                  img="/patient/cardIcon1.png"
                  title="Ciclo actual"
                  info={dashboardInfo.cycle ?? ""}
                />
                <InfoCard
                  img="/patient/cardIcon2.png"
                  title="Instilación actual"
                  info={dashboardInfo.instillation ?? ""}
                />
                <InfoCard
                  img="/patient/cardIcon3.png"
                  title="Próxima"
                  info={
                    !dashboardInfo.nextInstillation
                      ? ""
                      : dayjs(dashboardInfo.nextInstillation).format(
                          "DD/MM/YYYY"
                        )
                  }
                />
              </div>

              <CircleProgress progress={dashboardInfo.progress ?? 0} />
            </div>
            <a className="a-end-b" onClick={() => onClick( dashboardInfo)}>
              Ver evolución
            </a>
            
            <img
              className={`${styles.line}`}
              src="/patient/Line1.svg"
              alt="bar"
            />
            <InfoCard
              img="/patient/cardIcon4.png"
              title="Médico tratante"
              info={dashboardInfo.doctor ?? ""}
            />
          </div>
        </div>
      </div>

      <RelevantInformation />
      <GeneralRecommendations />
      <Progress loader={loader} />

      <Footer />

      {/* NOTIFICATION*/}
      <Dialog
        className={classes.root}
        open={open}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"¿Quieres activar las notificaciones en este dispositivo?"}</DialogTitle>
        <DialogContent>
          <div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className={"div-horizontal"}>
                <Button2 label="Si" onClick={()=>{setNotifications(true)}} />
                <Button2 label="No" onClick={()=>{setNotifications(false)}} />
              </div>
            </div>
            <div
              style={{ margin: "10px 0 0 0" }}
              className={"div-horizontal"}
            ></div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PatientDashboard;
