import React from 'react'
import { useHistory } from "react-router-dom";
import styles from '../styles/patientStyles/Errors.module.css'

const Main500 = () => {
  const history = useHistory();

  const onClick = () => {
    history.push('/')
  }

  return (
    <div className={styles.errorContainer}>
      <img className={`${styles.img}`} src="/patient/500.png" alt="500" />
      <p className={styles.text}>
        Lo sentimos, ha ocurrido un error interno en el servidor y ya estamos
        solucionándolo. Si necesita ayuda inmediata contáctenos en
        <b> equipovirtual@seranestpharma.com</b>
      </p>

      <button onClick={onClick} className={styles.button}>
        <p className={styles.buttonContent}> Ir al inicio</p>
      </button>

    </div>
  )
}

export default Main500
