import React from "react";

const RecommendationCard2 = () => {
  return (
    <>
      <div className="recommendationBox1">
        <div className="recommendationImgBox">
          <div className="numberBox">
            <p className="number">
              <b className="whiteBold">1</b>
            </p>
          </div>
          <div className="imgBox2">
            <img
              className="imgRecomendation1"
              src="/doctor/recomendaciones-art2-01.png"
              alt="Info"
            />
          </div>
        </div>
        <div>
          <p className="colorText">
            Realizar aseo genital{" "}
            <b className="b">únicamente con agua y jabón</b>
          </p>
        </div>
      </div>

      <div className="recommendationBox2">
        <div className="recommendationImgBox2">
          <div className="imgBox2">
            <img
              className="imgRecomendation1"
              src="/doctor/recomendaciones-art2-02.png"
              alt="Info"
            />
          </div>
          <div className="numberBox2">
            <p className="number2">
              <b style={{ color: "#b06db4" }}>2</b>
            </p>
          </div>
        </div>
        <div>
          <p className="whiteText">
            Recuerde que{" "}
            <b className="whiteBold">no es necesario que acuda en ayunas</b>
          </p>
        </div>
      </div>

      <div className="recommendationBox5">
        <div className="recommendationImgBox">
          <div className="numberBox">
            <p className="number">
              <b className="whiteBold">3</b>
            </p>
          </div>
          <div className="imgBox2">
            <img
              className="imgRecomendation1"
              src="/doctor/recomendaciones-art2-03.png"
              alt="Info"
            />
          </div>
        </div>
        <div>
          <p className="colorText">
            Asistir <b className="b">15 minutos antes</b> de la hora programada
          </p>
        </div>
      </div>

      <div className="recommendationBox2">
        <div className="recommendationImgBox2">
          <div className="imgBox2">
            <img
              className="imgRecomendation1"
              src="/doctor/recomendaciones-art2-04.png"
              alt="Info"
            />
          </div>
          <div className="numberBox2">
            <p className="number2">
              <b style={{ color: "#b06db4" }}>4</b>
            </p>
          </div>
        </div>
        <div>
          <p className="whiteText">
            No olvide llevar consigo:{" "}
            <b className="whiteBold">
              orden médica, historia clínica, documento de identificación y
              autorización
            </b>
          </p>
        </div>
      </div>

      <div className="recommendationBox5">
        <div className="recommendationImgBox">
          <div className="numberBox">
            <p className="number">
              <b className="whiteBold">5</b>
            </p>
          </div>
          <div className="imgBox2">
            <img
              className="imgRecomendation1"
              src="/doctor/recomendaciones-art2-05.png"
              alt="Info"
            />
          </div>
        </div>
        <div>
          <p className="colorText">
            Le recomendamos <b className="b">no llevar objetos de valor</b>
          </p>
        </div>
      </div>

      <div className="recommendationBox2">
        <div className="recommendationImgBox2">
          <div className="imgBox2">
            <img
              className="imgRecomendation1"
              src="/doctor/recomendaciones-art2-06.png"
              alt="Info"
            />
          </div>
          <div className="numberBox2">
            <p className="number2">
              <b style={{ color: "#b06db4" }}>6</b>
            </p>
          </div>
        </div>
        <div>
          <p className="whiteText">
            Antes de ingresar al procedimiento debe{" "}
            <b className="whiteBold">evacuar la vejiga</b>
          </p>
        </div>
      </div>

      <div className="recommendationBox5">
        <div className="recommendationImgBox">
          <div className="numberBox">
            <p className="number">
              <b className="whiteBold">7</b>
            </p>
          </div>
          <div className="imgBox2">
            <img
              className="imgRecomendation1"
              src="/doctor/recomendaciones-art2-07.png"
              alt="Info"
            />
          </div>
        </div>
        <div>
          <p className="colorText">
            Si la IPS requiere que lleve consigo el producto ialuril ®Prefill,{" "}
            <b className="b">no olvide llevarlo</b>
          </p>
        </div>
      </div>

      <div className="recommendationBox4">
        <div className="recommendationImgBox2">
          <div className="imgBox2">
            <img
              className="imgRecomendation1"
              src="/doctor/recomendaciones-art2-08.png"
              alt="Info"
            />
          </div>
          <div className="numberBox2">
            <p className="number2">
            <b style={{color:"#b06db4"}}>8</b>
            </p>
          </div>
        </div>
        <div>
          <p className="whiteText">
            Recuerde antes de salir{" "}
            <b className="whiteBold">programar su siguiente instilación</b>,
            para no interrumpir el cliclo de tratamiento
          </p>
        </div>
      </div>
    </>
  );
};

export default RecommendationCard2;
