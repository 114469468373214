import React, { useEffect } from "react";

const EducationCard2 = () => {
  return (
    <>
      <div className="educationCard1">
        <div className="imgBox1">
          <img className="imgEducation2" src="/doctor/edu-art2-01.png" alt="Info" />
        </div>
        <div className="educationBox2">
          <p className="whiteText">
            La instilación intraversical es un procedimiento que{" "}
            <b className="whiteBold">
              está incluido en el plan de beneficios en salud (PBS)
            </b>{" "}
            al cual usted pertenece
          </p>
        </div>
      </div>

      <div className="educationCard1">
        <div className="imgBox3">
          <img className="imgEducation3" src="/doctor/edu-art2-02.png" alt="Info" />
        </div>
        <div className="educationBox2">
          <p className="whiteText">
            <b className="whiteBold">
              Se realiza en la Institución Prestadora de Salud (IPS) asignada
              para realizar el procedimiento
            </b>{" "}
            al cual usted pertenece
          </p>
        </div>
      </div>

      <div className="educationCard1">
        <div className="imgBox3">
          <img className="imgEducation3" src="/doctor/edu-art2-03.png" alt="Info" />
        </div>
        <div className="educationBox3">
          <p className="whiteText">
            <b className="whiteBold">
              O en el consultorio de su médico tratante
            </b>{" "}
            si este se realiza de manera particular
          </p>
        </div>
      </div>
    </>
  );
};

export default EducationCard2;
