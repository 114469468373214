export const getUserInfo = () => {
  const userInfo = localStorage.getItem('userInfo')
  if (!userInfo) return null
  return JSON.parse(userInfo)
}

export const saveUserInfo = (userInfo) => {
  localStorage.setItem('userInfo', JSON.stringify(userInfo))
}

export const signOut = () => localStorage.removeItem('userInfo')
